import axios from 'axios';

const currentUrl = window.location.href;
let stage = '';
if (currentUrl.includes('test') || currentUrl.includes('localhost')) {
    stage = 'test';
} else if (currentUrl.includes('stage')) {
    stage = 'stage';
} else {
    stage = 'prod';
}

const api = axios.create({
    // baseURL: 'http://localhost:4000/api',
    // baseURL: 'https://gocochain-fullstack.onrender.com/api'
    // baseURL: 'https://wzsz2qej59.execute-api.us-west-2.amazonaws.com/test/',
    // baseURL: 'https://wzsz2qej59.execute-api.us-west-2.amazonaws.com/dynamo/',
    // find out test stage prod by url
    baseURL: `https://nnjxa3wrgg.execute-api.us-west-2.amazonaws.com/dynamo/${stage}/api/`,
    // headers: {
    //     Authorization: `Bearer ${localStorage.getItem('token')}`
    // }
});

api.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const getRecords = async () => {
    const { data } = await api.get('/records');
    return data;
};

const getNRecords = async (offset, limit, start_time, end_time) => {
    // let req = {params: {offset, limit}};
    let req = { offset, limit, start_time, end_time };
    // const { data } = await api.get('/nrecords', req);
    const { data } = await api.post('/getRecords', req);
    return data;
};

const getNotStarted = async (offset, limit, start_time, end_time) => {
    // let req = {params: {offset, limit}};
    let req = { offset, limit, start_time, end_time };
    // const { data } = await api.get('/nrecords', req);
    const { data } = await api.post('/getNotStarted', req);
    return data;
};

const getRealTime = async (start_time, end_time) => {
    let params = { start_time, end_time };
    const { data } = await api.get('/realtime/records', { params });
    return data;
}

const getRecordById = async (device_id, index) => {
    let req = { device_id, index };
    const { data, status } = await api.post('/getRecordById', req);
    return { statusCode: status, body: data };
};

const getRealTimeById = async (device_id, start_time, end_time) => {
    let params = { device_id, start_time, end_time };
    const { data } = await api.get('/realtime/record', { params });
    return data;
}

const getReportData = async (device_id, index) => {
    let req = { device_id, index };
    const { data, status } = await api.post('/getReportData', req);
    return { statusCode: status, body: data };
}

const updateRecord = async (device_id, index, record) => {
    let req = { device_id, index, record };
    const { data } = await api.put(`/updateRecord`, req);
    return data;
};

const updateRecords = async (device_ids, indexs, record) => {
    let req = { device_ids, indexs, record };
    const { data } = await api.put(`/updateRecords`, req);
    return data;
};

const updateRealtime = async (device_ids, record) => {
    let req = { device_ids, record };
    const { data } = await api.put(`/updateRealtime`, req);
    return data;
};

const login = async (email, password) => {
    const req = { email, password };
    const { data } = await api.post('/login', req);
    return data;
};

const signup = async (email, password, display_name, country_code, phone_number, language) => {
    const req = { email, password, display_name, country_code, phone_number, language };
    const { data } = await api.post('/signup', req);
    return data;
};

const verify = async (email, token) => {
    const req = { email, token };
    const { data } = await api.post('/verify', req);
    return data;
};

const forgot = async (email, language) => {
    const req = { email, language };
    const { data } = await api.post('/forgot', req);
    return data;
};

const reset = async (email, token, password) => {
    const req = { email, token, password };
    const { data } = await api.post('/reset', req);
    return data;
};

const getInformation = async () => {
    const { data } = await api.post('/getInformation');
    return data;
};

const getInformationById = async (id) => {
    const { data } = await api.get(`/information/${id}`);
    return data;
};
const updateInformation = async (newInfo) => {
    // const req = {company_name, telephone, mobilephone, email, address, website_url, lineaccount, note}
    const { data } = await api.put('/updateInformation', { newInfo });
    return data;
};

const getOverview = async (start_time, end_time) => {
    const req = { start_time, end_time };
    const { data } = await api.post('/getOverview', req);
    return data;
};

const addRecord = async (device_ids) => {
    const req = { device_ids };
    const { data } = await api.post('/addRecord', req);
    return data;
};

export {
    getRecords,
    getNRecords,
    getNotStarted,
    getRealTime,
    getRecordById,
    getRealTimeById,
    getReportData,
    login,
    signup,
    verify,
    forgot,
    reset,
    getInformation,
    getInformationById,
    updateRecord,
    updateRecords,
    updateRealtime,
    updateInformation,
    getOverview,
    addRecord
};
