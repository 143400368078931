import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box } from '@mui/material';
import './Add.css';
import { addRecord } from '../../api';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'40rem',
    bgcolor: '#FFFFFF',
    borderRadius: '1rem',
    boxShadow: '0px 4px 25px 0px #EAEE9E9',
    p: 0,
};

function Add(props) {

    const { openAdd, setOpenAdd, setSaveAlert, setAlertState, translation, handleAddUpdate } = props;

    const [view, setView] = React.useState('add');

    const [deviceIds, setDeviceIds] = React.useState([{ id: 1, value: '' }]);

    const handleInputChange = (index, event) => {
        // convert lowercase to uppercase
        // max length 8
        event.target.value = event.target.value.toUpperCase();
        if (event.target.value.length > 8) {
            event.target.value = event.target.value.slice(0, 8);
        }
        const newDeviceIds = [...deviceIds];
        newDeviceIds[index].value = event.target.value;
        setDeviceIds(newDeviceIds);
    };

    const handleAddItem = () => {
        setDeviceIds([...deviceIds, { id: deviceIds.length + 1, value: '' }]);
    };

    const handleRemoveItem = () => {
        if (deviceIds.length > 1) {
            setDeviceIds(deviceIds.slice(0, -1));
        }
    };

    const handleAdd = (event) => {
        const target = event.target.id;
        if (target === 'add') {
            // setDeviceId(deviceIdInput.current.value);
            setView('confirm');
        }
        if (target === 'confirm') {
            setView('confirm');
        }
    }

    const handleCancel = () => {
        setView('add');
    }

    const handleConfirm = () => {
        const device_ids = deviceIds.map((deviceId) => deviceId.value);

        addRecord(device_ids).then((res) => {
            if (res.status === 'success') {
                handleAddUpdate();
                setSaveAlert(true);
                setAlertState({severity: 'success', message: translation.addSuccess});
            } else {
                setSaveAlert(true);
                setAlertState({severity: 'error', message: translation.addFail});
            }
        });

        setOpenAdd(false);
        setView('add');
    }

    return (
        <Modal
            open={openAdd}
            onClose={() => setOpenAdd(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <div className='button__container'>
                    <button className="close" onClick={() => {
                        setView('add');
                        setOpenAdd(false)
                    }}>&times;</button>
                </div>
                <div style={{display: view === 'add' ? 'inline' : 'none'}}>
                    <div className="modal__content">
                        <form className="modal__form__container" id='addForm'>
                            <div className='title'>{translation.addCotag}</div>
                            {deviceIds.map((deviceId, index) => (
                                <div key={deviceId.id} className="modal__form__item">
                                    <label className="modal__form__item__label">{translation.deviceId} - {deviceId.id}</label>
                                    <div className="vline"></div>
                                    <input
                                        name={`deviceId${deviceId.id}`}
                                        className="modal__form__item__input"
                                        type="text"
                                        placeholder="XXXXXXXX"
                                        value={deviceId.value}
                                        onChange={(event) => handleInputChange(index, event)}
                                    />
                                </div>
                            ))}
                        </form>      
                    </div>
                    <div className='icon__container'>
                        <button className='icon' onClick={handleAddItem}><AddCircleOutlineIcon/></button>
                        <button className='icon' onClick={handleRemoveItem}><RemoveCircleOutlineIcon/></button>
                    </div>
                    <div className='button__container'>
                        <button id='add' className='save__all' onClick={handleAdd}>
                            {translation.add}
                        </button>
                    </div>
                </div>
                <div style={{display: view === 'confirm' ? 'inline' : 'none'}}>
                    <div className="modal__content">
                        {deviceIds.map((deviceId) => (
                            <p key={deviceId.id} className='confirmText'>{deviceId.value}</p>
                        ))}
                        <p className='confirmText'>{translation.confirmText}</p>
                        <button id='cancel' onClick={handleCancel}>{translation.cancel}</button>
                        <button id='confirm' onClick={handleConfirm}>{translation.confirm}</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default Add;