import { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { verify } from '../api';
import logo from '../images/logo-login.png';
import './Signup.css';

function Signup(props) {
    const { setCompanyName, setLogin, handleLanguageChange, translation } = props;

    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState('success');

    // get email and token from url params
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');
    const language = urlParams.get('language');

    if (language === 'english' || language === 'chinese') {
        handleLanguageChange(language);
    }

    // put in useEffect else it execute 4 times
    useEffect(() => {
        // verify email and token
        if (email && token) {
            verify(email, token)
                .then((data) => {
                    console.log('signup: ', data);
                    if (data && data.success) {
                        setStage('success');
                    } else {
                        alert('fail');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    function redirectToLogin() {
        Navigate('/login');
    }

    return (
        <div style={{ height: '100vh', minWidth: '1000px', minHeight: '40rem', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-around', verticalAlign: 'center', position: 'relative' }}>
            {/*<h1>Login</h1>*/}
            {/* <img src={logo} style={{width: '510px', height: '510px',paddingTop: '150px', backgroundColor: '#F323423'}}/> */}
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div style={{ width: '28.5rem', height: '30rem', display: stage === 'success' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <img src={logo} className='logo' style={{ width: '12.5rem', position: 'absolute', top: '1rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginTop: '7rem', marginBottom: '2rem' }}>{translation.successAccount}</p>
                        <p className='complete'>{translation.successNote}</p>
                        {/* <input className='signup' type='submit' value='Return to login' style={{ width: '9.25rem', height: '3rem', marginTop: '6rem', borderRadius: '1.5rem' }} /> */}
                        {/* <button className='signup blue' type='submit' style={{ width: '10rem' }}>
                            Return to login
                        </button> */}
                        {/* <a href='/login' className='signup blue' style={{ width: '9.25rem', marginTop: '6rem' }}>
                            {translation.return}
                        </a> */}
                        <Link to='/login' className='signup blue link' style={{ width: '9.25rem', marginTop: '6rem' }}>
                            {translation.return}
                        </Link>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                {translation.question} <a href='mailto:support@gocochain.com'>support@gocochain.com</a>
                            </p>
                        </div>
                    </div>
                </>
            )}
            <p style={{ position: 'absolute', bottom: '0', minWidth: '1000px', textAlign: 'center' }}>© 2024 Gocochain Co., Ltd. All rights reserved.</p>
        </div>
    );
}

export default Signup;
