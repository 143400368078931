function roundHalf(num) {
    if (num === "") {
        return ""
    }
    return Math.round(num*2)/2;
}

export function predict_missed(his){

    his = his.map(temp => {
        if (temp === -1000) {
            return ""
        }
        return temp
    })


    let prev = undefined
    let end = undefined
    let accu = 0

    let max_step = 4 // should be 2*n
    for (let i = 0; i < his.length; i++) {
        if(his[i] !== ""){ // 最後面的不換
            if(accu > 0 && prev !== undefined){ // 最前面的不換
                let end = his[i]
                let start = i - accu - 1
                let diff = end - prev

                if (accu <= max_step) {
                    let step = diff / (accu + 1)
                    for(let j = 0; j < accu; j++){
                        his[i-1-j] = end - step * (j+1)
                    }
                }
                else{
                    let step = diff / (max_step + 1)

                    for(let j = 0; j < max_step/2; j++){
                        his[i-1-j] = end - step * (j+1)
                    }
                    // his[i-1] = end - step
                    // his[i-2] = end - step * 2

                    for(let j = 0; j < max_step/2; j++){
                        his[i-accu+j] = his[start] + step * (j+1) // ensured that his[start] is not ""
                    }
                    // his[i-accu] = his[start] + step
                    // his[i-accu+1] = his[start] + step * 2
                }
            }
            prev = his[i]
            accu = 0
        }
        else{
            accu += 1
        }
    }
    his = his.map(roundHalf)
    return his
}