import { useEffect, useRef } from 'react';

function CustomInput(props) {
    const { setInputValue, lock, handleConfirm } = props;

    const urlParams = new URLSearchParams(window.location.search);
    const url_device_id = urlParams.get('device_id') || '';

    useEffect(() => {
        setInput(url_device_id);
    });

    const inputRef = useRef([]);

    function getDeviceID() {
        let value = '';
        for (let i = 0; i < 8; i++) {
            value += inputRef.current[i].value;
        }
        return value;
    }

    function setInput(value) {
        for (let i = 0; i < 8; i++) {
            if (value[i] === undefined) {
                break;
            }
            inputRef.current[i].value = value[i];
        }
    }

    function handleInput(e) {
        if (e.target.value !== '') {
            if (e.target.nextSibling) {
                e.target.nextSibling.focus();
            }
        }

        setInputValue(getDeviceID());
    }

    function handleKeyDown(e) {
        if (e.key === 'Backspace') {
            if (e.target.value === '') {
                if (e.target.previousSibling) {
                    e.target.previousSibling.value = '';
                    e.target.previousSibling.focus();

                    setInputValue(getDeviceID());
                }
            }
        } else if (e.key === 'Enter') {
            handleConfirm();
        }
    }

    function handleKeyUp(e) {
        // if (e.target.value !== '') {
        //     if (e.target.nextSibling) {
        //         e.target.nextSibling.focus();
        //     }
        // }
    }

    function handleFocus(e) {
        if (e.target.value === '') {
            if (e.target.previousSibling && e.target.previousSibling.value === '') {
                e.target.previousSibling.focus();
            }
        } else {
            if (e.target.nextSibling) {
                e.target.nextSibling.focus();
            }
        }
        e.target.setSelectionRange(1, 1);
    }

    function handlePaste(e) {
        e.preventDefault();
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let current = e.target;
        while (paste.length > 0) {
            current.value = paste[0];
            paste = paste.slice(1);
            if (!current.nextSibling) {
                break;
            }
            current = current.nextSibling;
            current.focus();
        }

        setInputValue(getDeviceID());
    }

    function onInput(e) {
        // convert to uppercase
        e.target.value = e.target.value.toUpperCase();
    }

    return (
        <div className='custom-input'>
            <input type='text' disabled={lock} placeholder='A' className='device-id' maxLength={1} ref={(el) => (inputRef.current[0] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='B' className='device-id' maxLength={1} ref={(el) => (inputRef.current[1] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='C' className='device-id' maxLength={1} ref={(el) => (inputRef.current[2] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='D' className='device-id' maxLength={1} ref={(el) => (inputRef.current[3] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='1' className='device-id' maxLength={1} ref={(el) => (inputRef.current[4] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='2' className='device-id' maxLength={1} ref={(el) => (inputRef.current[5] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='3' className='device-id' maxLength={1} ref={(el) => (inputRef.current[6] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
            <input type='text' disabled={lock} placeholder='4' className='device-id' maxLength={1} ref={(el) => (inputRef.current[7] = el)} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onChange={handleInput} onFocus={handleFocus} onPaste={handlePaste} onInput={onInput} />
        </div>
    );
}

export default CustomInput;

// return (
//     <div>
//         <input type='text' placeholder='1' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[0] = el}/>
//         <input type='text' placeholder='2' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[1] = el}/>
//         <input type='text' placeholder='3' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[2] = el}/>
//         <input type='text' placeholder='4' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[3] = el}/>
//         <input type='text' placeholder='5' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[4] = el}/>
//         <input type='text' placeholder='6' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[5] = el}/>
//         <input type='text' placeholder='7' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[6] = el}/>
//         <input type='text' placeholder='8' className='device-id' maxLength={1} tabbable='true' ref={el => inputRef.current[7] = el}/>
//     </div>
// )
