import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { reset } from '../api';
import logo from '../images/logo-login.png';
import './Signup.css';

function Signup(props) {
    const { setCompanyName, setLogin, handleLanguageChange, translation } = props;

    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState('entry');
    // get email and token from url params
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');
    const language = urlParams.get('language');

    if (language === 'english' || language === 'chinese') {
        handleLanguageChange(language);
    }

    const onSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const password = e.target.password.value;
        const password_confirm = e.target.passwordConfirm.value;

        if (password !== password_confirm) {
            alert('Passwords do not match');
            setLoading(false);
            return;
        }

        reset(email, token, password).then((data) => {
            console.log('signup: ', data);
            if (data && data.success) {
                setLoading(false);
                setStage('success');
            } else {
                setLoading(false);
                alert('Signup failed');
            }
        });
    };

    function redirectToLogin() {
        Navigate('/login');
    }

    return (
        <div style={{ height: '100vh', minWidth: '1000px', minHeight: '40rem', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-around', verticalAlign: 'center', position: 'relative' }}>
            {/*<h1>Login</h1>*/}
            {/* <img src={logo} style={{width: '510px', height: '510px',paddingTop: '150px', backgroundColor: '#F323423'}}/> */}
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div style={{ width: '28.5rem', height: '30rem', display: stage === 'entry' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <img src={logo} className='logo' style={{ width: '12.5rem', position: 'absolute', top: '1rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginTop: '5rem', marginBottom: '1rem' }}>{translation.reset}</p>
                        <form onSubmit={onSubmit} style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                            <label className='signup' htmlFor='password' style={{ width: '21rem' }}>
                                {translation.newPassword}
                            </label>
                            <input className='signup' id='password' type='password' placeholder='' style={{ marginBottom: '1rem' }} />
                            <label className='signup' htmlFor='password' style={{ width: '21rem' }}>
                                {translation.confirmPassword}
                            </label>
                            <input className='signup' id='passwordConfirm' type='password' placeholder='' style={{ marginBottom: '0' }} />
                            <p className='signup' style={{ width: '20rem', marginTop: '0', color: '#ADABAB', fontSize: '0.625rem' }}>
                                {translation.passwordCheck}
                            </p>
                            {/* <input className='signup' type='submit' value='Reset password' style={{ width: '9.25rem', height: '3rem', marginTop: '3rem', borderRadius: '1.5rem' }} /> */}
                            <button className='signup blue' type='submit' style={{ width: '10rem' }}>
                                {translation.reset}
                            </button>
                        </form>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                {translation.question} <a href='mailto:support@gocochain.com'>support@gocochain.com</a>
                            </p>
                        </div>
                    </div>

                    <div style={{ width: '28.5rem', height: '30rem', display: stage === 'success' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <img src={logo} className='logo' style={{ width: '12.5rem', position: 'absolute', top: '1rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginTop: '8rem', marginBottom: '1rem' }}>{translation.success}</p>
                        <p className='complete'>{translation.resetSuccess}</p>
                        {/* <input className='signup' type='button' value='Return to login' style={{ width: '9.25rem', height: '3rem', marginTop: '6rem', borderRadius: '1.5rem' }} onClick={onReturn} /> */}
                        {/* <button className='signup blue' type='submit' style={{ width: '10rem' }}>
                            Return to login
                        </button> */}
                        {/* <a href='/login' className='signup blue' style={{ width: '9.25rem', marginTop: '6rem' }}>
                            {translation.return}
                        </a> */}
                        <Link to='/login' className='signup blue link' style={{ width: '9.25rem', marginTop: '6rem' }}>
                            {translation.return}
                        </Link>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                {translation.question} <a href='mailto:support@gocochain.com'>support@gocochain.com</a>
                            </p>
                        </div>
                    </div>
                </>
            )}
            <p style={{ position: 'absolute', bottom: '0', minWidth: '1000px', textAlign: 'center' }}>© 2024 Gocochain Co., Ltd. All rights reserved.</p>
        </div>
    );
}

export default Signup;
