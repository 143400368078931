import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { forgot } from '../api';
import logo from '../images/logo-login.png';
import './Signup.css';

function Forgot(props) {
    const { setCompanyName, setLogin, language, translation } = props;

    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState('entry');

    const onSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const email = e.target.email.value;

        forgot(email, language).then((data) => {
            console.log('signup: ', data);
            if (data && data.success) {
                setLoading(false);
                setStage('success');
            } else {
                setLoading(false);
                alert('Signup failed');
            }
        });
    };

    function redirectToLogin() {
        Navigate('/login');
    }

    return (
        <div style={{ height: '100vh', minWidth: '1000px', minHeight: '40rem', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center', verticalAlign: 'center', position: 'relative' }}>
            {/*<h1>Login</h1>*/}
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div style={{ width: '28.5rem', height: '30rem', display: stage === 'entry' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <img src={logo} className='logo' style={{ width: '12.5rem', position: 'absolute', top: '1rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginTop: '4rem', marginBottom: '1rem' }}>Reset password</p>
                        <p className='complete' style={{ marginBottom: '1rem', color: '#ADABAB', width: '90%' }}>
                            {translation.resetNote1}
                            <br />
                            {translation.resetNote2}
                        </p>
                        <form onSubmit={onSubmit} style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                            <label className='signup' htmlFor='email' style={{ width: '21rem' }}>
                                {translation.email}
                            </label>
                            <input className='signup' id='email' type='text' placeholder='' name='email' />
                            {/* <input className='signup' type='submit' value='Send' style={{ width: '9.25rem', height: '3rem', marginTop: '4rem', borderRadius: '1.5rem' }} /> */}
                            <button className='signup blue' type='submit' style={{ width: '9.25rem' }}>
                                {translation.send}
                            </button>
                        </form>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                {translation.question} <a href='mailto:support@gocochain.com'>support@gocochain.com</a>
                            </p>
                        </div>
                    </div>

                    <div style={{ width: '28.5rem', height: '30rem', display: stage === 'success' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <img src={logo} className='logo' style={{ width: '12.5rem', position: 'absolute', top: '1rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginTop: '5rem', marginBottom: '2rem' }}>{translation.mailSuccess}</p>
                        <p className='complete' style={{ width: '90%' }}>
                            {translation.resetSuccessNote}
                        </p>
                        {/* <input className='signup' type='submit' value='Return to login' style={{ width: '9.25rem', height: '3rem', marginTop: '6rem', borderRadius: '1.5rem' }} /> */}
                        {/* <button className='signup blue' type='submit' style={{ width: '10rem' }}>
                            Return to login
                        </button> */}
                        {/* <a href='/login' className='signup blue' style={{ width: '9.25rem', marginTop: '6rem' }}>
                            {translation.return}
                        </a> */}
                        <Link to='/login' className='signup blue link' style={{ width: '9.25rem', marginTop: '6rem' }}>
                            {translation.return}
                        </Link>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                {translation.question} <a href='mailto:support@gocochain.com'>support@gocochain.com</a>
                            </p>
                        </div>
                    </div>
                </>
            )}
            <p style={{ position: 'absolute', bottom: '0', minWidth: '1000px', textAlign: 'center' }}>© 2024 Gocochain Co., Ltd. All rights reserved.</p>
        </div>
    );
}

export default Forgot;
