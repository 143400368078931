import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import Exit from '../../images/exit.png';

function Header(props) {
    const { title, companyName, logout, language, setLanguage, setLogin, color } = props;

    const Navigate = useNavigate();

    // const [companyName, setCompanyName] = useState(localStorage.getItem('companyName'));

    const onLogout = () => {
        localStorage.removeItem('token');
        setLogin(false);
        // localStorage.removeItem("companyName");
        Navigate('/login');
    };

    return (
        <div className='head' style={{ backgroundColor: color === 'light' ? '#FFFFFF' : '#262729', width: '100%', height: '60px' }}>
            <div className='overview' style={{ fontSize: '26px', lineHeight: '30px', color: color === 'light' ? '#1E2B7C' : '#FFFFFF', margin: '12px 0px', padding: '0px 20px', display: 'inline-block', fontFamily: 'Roboto', fontWeight: '600' }}>
                {title}
            </div>
            {/* <Link to='/'> */}
            <button className='exit' onClick={onLogout} style={{ fontSize: '15px', margin: '18px', lineHeight: '20px', color: color === 'light' ? '#757575' : '#FFFFFF', width: '80px', display: 'inline-block', float: 'right', fontFamily: 'Roboto', fontWeight: '600', border: 'none', backgroundColor: color === 'light' ? '#FFFFFF' : '#262729', cursor: 'pointer' }}>
                {logout}
                <img src={Exit} alt='exit' style={{ width: '20px', verticalAlign: 'middle' }} />
            </button>
            {/* </Link> */}
            {/* <Select
                native
                value={language}
                onChange={(event) => {
                    setLanguage(event.target.value);
                }}
                inputProps={{
                    name: 'language',
                    id: 'uncontrolled-native',
                }}
                style={{ fontSize: '20px', margin: '0px', color: color === 'light' ? '#757575' : '#FFFFFF', display: 'inline-block', float: 'right', fontFamily: 'Roboto', fontWeight: '600' }}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
                <option value='en'>English</option>
                <option value='ch'>中文</option>
            </Select> */}
            <div className='company' style={{ fontSize: '20px', margin: '20px', lineHeight: '20px', color: color === 'light' ? '#757575' : '#FFFFFF', display: 'inline-block', float: 'right', fontFamily: 'Roboto', fontWeight: '600' }}>
                {companyName}
            </div>
        </div>
    );
}

export default Header;
