import CertLogo from '../../images/cert-logo.png';
import './Cert.css';

function Cert(props) {
    const { certData } = props;
    const { calibrationPoints, calibrationTime, expirationTime, id, model_info } = certData;

    return (
        <div className='cert' style={{ width: '570pt', margin: '10pt' }}>
            <p className='cert-title'>
                Calibration Certification
                <img src={CertLogo} alt='logo' style={{ display: 'inline', width: '200px', marginLeft: '200px' }} />
            </p>

            <table className='product-info'>
                <tbody>
                    <tr>
                        <td>Model</td>
                        <td>{model_info?.model}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>Temperature Data Logger</td>
                    </tr>
                    <tr>
                        <td>Device ID</td>
                        <td>{id}</td>
                    </tr>
                    <tr>
                        <td>Calibration Date</td>
                        <td>{calibrationTime}</td>
                    </tr>
                    <tr>
                        <td>Expiration Date</td>
                        <td>{expirationTime}</td>
                    </tr>
                </tbody>
            </table>

            <div className='company-info'>
                <p className='company-name'>Gocochain Co. Ltd.</p>
                <p>
                    2 F.-2, No. 285, Sec. 2, Guangfu Rd. <br />
                    East Dist., Hsinchu City 300195 <br />
                    Taiwan (R.O.C.)
                </p>
                <p>support@gocochain.com</p>
            </div>

            <p className='cert-title'>Temperature Accuracy</p>

            <table className='cert-table'>
                <thead>
                    <tr>
                        <th>Validation Points</th>
                        <th>Indicated Points</th>
                        <th>Variance</th>
                        <th>Tolerance</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody>
                    {calibrationPoints.map((point, index) => {
                        return (
                            <tr key={index}>
                                <td>{point.validation_point}°C</td>
                                <td>{point.indication_point}°C</td>
                                <td>{point.indication_point - point.validation_point}°C</td>
                                <td>±{point.tolerance}°C</td>
                                <td>{point.result}</td>
                            </tr>
                        );
                    })}
                    {/* <tr>
                        <td>+40°C</td>
                        <td>+39.5°C</td>
                        <td>-0.5</td>
                        <td>±0.5°C</td>
                        <td>Pass</td>
                    </tr>
                    <tr>
                        <td>0°C</td>
                        <td>0°C</td>
                        <td>0</td>
                        <td>±0.5°C</td>
                        <td>Pass</td>
                    </tr>
                    <tr>
                        <td>-10°C</td>
                        <td>-10.5°C</td>
                        <td>0.5</td>
                        <td>±0.5°C</td>
                        <td>Pass</td>
                    </tr> */}
                </tbody>
            </table>

            <p className='cert-title'>Description</p>
            <p className='description'>
                Each temperature logger is subject to a detailed calibration procedure in a controlled lab setting before dispatch. Placed in a constant-temperature chamber, the device undergoes calibration at three specific temperature points. This step confirms that the logger's temperature measurements are within our defined acceptable range. A temperature logger passes calibration only if it meets the criteria at all three points, ensuring accuracy and reliability for field use. For queries about calibration process, please visit <span className='bold'>www.gocochain.com/help</span> for more information, or contact us at <span className='bold'>support@gocochain.com</span> for assistance.
            </p>
        </div>
    );
}

export default Cert;
