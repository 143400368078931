
import './Panel.css'


function Panel(props) {
    const {translation, uploadToday, errorToday, uploadWeek, errorWeek} = props;
    // const {allCountList, errCountList, records} = props;
    // console.log(allCountList)
    // const uploadToday = allCountList[allCountList.length - 1] ? allCountList[allCountList.length - 1] : {count: 0, date: '2021-01-01'};
    // const errSum = errCountList.reduce((a, b) => a + parseInt(b.count), 0);
    // console.log(props.uploadToday);
    // console.log(translation, uploadToday, errorToday, uploadWeek, errorWeek)
    return (
        <div className='panel'>
            <div className='panel-container'>
                <div className='panel-title-box pass'>
                    <p className='panel-title'>{translation.dayUpload}</p>
                </div>
                <div className='panel-box'>
                    <p className='panel-value'>{uploadToday}</p>
                </div>
            </div>

            <div className='panel-container'>
                <div className='panel-title-box fail'>
                    <p className='panel-title'>{translation.dayError}</p>
                </div>
                <div className='panel-box'>
                    <p className='panel-value'>{errorToday}</p>
                </div>
            </div>

            <div className='panel-container'>
                <div className='panel-title-box pass'>
                    <p className='panel-title'>{translation.weekUpload}</p>
                </div>
                <div className='panel-box'>
                    <p className='panel-value'>{uploadWeek}</p>
                </div>
            </div>

            <div className='panel-container'>
                <div className='panel-title-box fail'>
                    <p className='panel-title'>{translation.weekError}</p>
                </div>
                <div className='panel-box'>
                    <p className='panel-value'>{errorWeek}</p>
                </div>
            </div>

            {/* <div className='panel-container'>
                <div className='panel-title fail'>本周未通過筆數</div>
                <div className='panel-box'>
                    <p className='panel-value'>{uploadToday !== '0' ? (errorToday/uploadToday*100).toFixed(0) : 0}%</p>
                </div>
            </div> */}
        </div>
        // <div style={{position: 'relative', width: '100%', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>
        //     <div style={{width: '45%', minWidth: '500px', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between'}}>
        //         <div style={{width: '40%', minWidth: '200px', height: '120px', borderRadius: '15px', backgroundColor: '#FFFFFF', display: 'flex', flexFlow: 'column wrap', margin: '10px', overflow: 'hidden'}}>
        //             <div style={{height: '100%', width: '5%', backgroundColor: '#3F8EE8'}}></div>
        //             <div style={{height: '100%', width: '95%', verticalAlign: 'center'}}>
        //                 <div style={{height: '20%', textAlign: 'center', margin: '6%', fontFamily: 'Roboto', fontWeight: '600'}}>本日上傳筆數</div>
        //                 <div className='panel-value'>{uploadToday}</div>
        //             </div>
        //         </div>
        //         <div style={{width: '40%', minWidth: '200px', height: '120px', borderRadius: '15px', backgroundColor: '#FFFFFF', display: 'flex', flexFlow: 'column wrap', margin: '10px', overflow: 'hidden'}}>
        //             <div style={{height: '100%', width: '5%', backgroundColor: '#3F8EE8'}}></div>
        //             <div style={{height: '100%', width: '95%', verticalAlign: 'center'}}>
        //                 <div style={{height: '20%', textAlign: 'center', margin: '6%', fontFamily: 'Roboto', fontWeight: '600'}}>本日未通過筆數</div>
        //                 <div className='panel-value'>{errorToday}</div>
        //             </div>
        //         </div>
        //     </div>
        //     <div style={{width: '45%', minWidth: '500px', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between'}}>
        //         <div style={{width: '40%', minWidth: '200px', height: '120px', borderRadius: '15px', backgroundColor: '#FFFFFF', display: 'flex', flexFlow: 'column wrap', margin: '10px', overflow: 'hidden'}}>
        //             <div style={{height: '100%', width: '5%', backgroundColor: '#3F8EE8'}}></div>
        //             <div style={{height: '100%', width: '95%', verticalAlign: 'center'}}>
        //                 <div style={{height: '20%', textAlign: 'center', margin: '6%', fontFamily: 'Roboto', fontWeight: '600'}}>本周未通過筆數</div>
        //                 <div className='panel-value'>{errorSum}</div>
        //             </div>
        //         </div>
        //         <div style={{width: '40%', minWidth: '200px', height: '120px', borderRadius: '15px', backgroundColor: '#FFFFFF', display: 'flex', flexFlow: 'column wrap', margin: '10px', overflow: 'hidden'}}>
        //             <div style={{height: '100%', width: '5%', backgroundColor: '#3F8EE8'}}></div>
        //             <div style={{height: '100%', width: '95%', verticalAlign: 'center'}}>
        //                 <div style={{height: '20%', textAlign: 'center', margin: '6%', fontFamily: 'Roboto', fontWeight: '600'}}>本日未通過比例</div>
        //                 <div className='panel-value'>{uploadToday !== '0' ? (errorToday/uploadToday*100).toFixed(0) : 0}%</div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Panel;