import Plot from 'react-plotly.js';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';

import dayjs from 'dayjs';

import './DataPlot.css';
import Progress from './Progress';

function createData(time, region, company, status) {
    return { time, region, company, status };
}

function DataPlot(props) {
    const { translation, allCountList, errCountList, overviewRecords, normal, abnormal, failAverageTime, errRate, timeKeys } = props;
    const now = dayjs();
    const timeOffset = now.utcOffset();
    const uploadData = overviewRecords.map((item, index) => {
        return createData(dayjs(item.upload_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), '', '', item.is_normal);
    });

    // const allSum = allCountList.reduce((a, b) => a + parseInt(b.count), 0);
    // const errSum = errCountList.reduce((a, b) => a + parseInt(b.count), 0);

    // const errRate = allSum === 0 ? 0 : (errSum / allSum * 100).toFixed(0);

    const failAverageTimeRound = failAverageTime ? failAverageTime.toFixed(0) : 0;
    const failMinute = failAverageTimeRound % 60;
    const failHour = ((failAverageTimeRound - failMinute) / 60) % 24;
    const failDay = (failAverageTimeRound - failMinute - failHour * 60) / 1440;

    // console.log('allCountList', allCountList);
    // let alltime = Object.keys(allCountList);
    // let errtime = Object.keys(errCountList);
    // alltime.sort();
    // errtime.sort();
    // let start_time = dayjs(alltime[0]);
    // console.log('start_time', start_time.format('YYYY-MM-DD HH:mm:ss'));
    // let end_time = dayjs(alltime[alltime.length - 1]);
    // console.log('end_time', end_time.format('YYYY-MM-DD HH:mm:ss'));
    // let days = end_time.diff(start_time, 'day');
    let days = timeKeys.length;
    let allList = [];
    let errList = [];
    for (let i = 0; i < days; i++) {
        // let day = start_time.add(i, 'day').format('YYYY-MM-DD');
        let day = timeKeys[i];
        // let allCount = allCountList.find((item) => item.start_time === day);
        // let errCount = errCountList.find((item) => item.start_time === day);
        allList.push({ time: day, count: allCountList[day] ? allCountList[day] : 0 });
        errList.push({ time: day, count: errCountList[day] ? errCountList[day] : 0 });
    }

    const interval = allList.length > 7 ? Math.round(allCountList.length / 7) : 1;

    let keys = ['time', 'region', 'company', 'status'];

    const quantity = (
        <div className='card' style={{ width: '45%', minWidth: '500px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center', margin: '10px 0px' }}>{translation.perDayUpload}</div>
            <Plot //What happend if count = 0 and one day
                data={[
                    {
                        type: 'line',
                        name: translation.uploadCount,
                        marker: {
                            color: '#82BDE7',
                            line: {
                                color: '#4485C6',
                                width: 1.5,
                            },
                        },
                        // tickformat: '%y-%d',
                        x: allList.map((item) => item.time),
                        y: allList.map((item) => item.count),
                        // fill: 'tozeroy',
                        // fillcolor: ['#82BDE7', '#FFFFFF', '#324323'],
                        // fillpattern: 'inside',
                    },
                    {
                        type: 'line',
                        name: translation.errorCount,
                        marker: {
                            color: '#F0929C',
                            line: {
                                color: '#E64644',
                                width: 1.5,
                            },
                        },
                        // tickformat: '%y-%d',
                        x: errList.map((item) => item.time),
                        y: errList.map((item) => item.count),
                        // fill: 'tozeroy',
                    },
                ]}
                layout={{
                    // title: '每日上傳筆數',
                    // width: '500',
                    // height: '300',
                    barmode: 'stack',
                    xaxis: {
                        tickformat: '%m/%d',
                        dtick: 86400000.0 * interval,
                        // dtick: '%d',
                    },
                    margin: {
                        l: 50,
                        r: 50,
                        b: 50,
                        t: 5,
                        pad: 4,
                    },
                }}
                config={{ responsive: true, staticPlot: true }}
                style={{ width: '100%', height: '90%' }}
            />
        </div>
    );

    const failTime = (
        <div className='card' style={{ width: '20%', minWidth: '300px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center' }}>{translation.failLastedTime}</div>
            <div style={{ width: '250px', height: '110px', backgroundColor: '#FFA8A8', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                <p style={{ width: '100px', margin: '30px 0px', color: '#FFFFFF', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '36px' }}>{translation.fail}</p>
                <div style={{ width: '150px', height: '110px', backgroundColor: '#FFFFFF', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flewFlow: 'row nowrap', justifyContent: 'center' }}>
                    <p style={{ margin: '18px 0px', color: '#FF3838', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '64px' }}>{failDay}</p>
                    <p style={{ margin: '35px 0px', color: '#53545C', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '32px' }}>{translation.day}</p>
                </div>
            </div>
            <div style={{ width: '250px', height: '110px', padding: '5px', backgroundColor: '#FFFFFF', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flewFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-evenly' }}>
                    <p style={{ margin: '26px 5px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '44px' }}>{failHour}</p>
                    <p style={{ margin: '40px 5px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '22px' }}>{translation.hour}</p>
                </div>

                <div className='vline'></div>

                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-evenly' }}>
                    <p style={{ margin: '26px 5px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '44px' }}>{failMinute}</p>
                    <p style={{ margin: '40px 5px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '22px' }}>{translation.minute}</p>
                </div>
            </div>
        </div>
    );

    const newPassTemperature = (
        <div className='card' style={{ width: '20%', minWidth: '300px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center' }}>{translation.passAverageTemp}</div>
            <div style={{ width: '80%', height: '70%', border: '2px #4485C6 solid', borderRadius: '15px', position: 'relative', backgroundColor: '#80C9FF80', overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start' }}>
                <div style={{ width: '125%', height: '50%', position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderRadius: '100% 100% 15px 15px', backgroundColor: '#FFFFFF' }}></div>
                <div style={{ height: '20px', fontSize: '25px', textAlign: 'center', color: '#4485C6', fontFamily: 'Inter', fontWeight: 'bold', marginTop: '5px' }}>{translation.pass}</div>
                <div style={{ height: '10px', fontSize: '35px', textAlign: 'right', color: '#4485C6', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%' }}>°C</div>
                <div style={{ height: '80px', fontSize: '90px', textAlign: 'center', color: '#4485C6', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', margin: '0px' }}>{normal.avg ? normal.avg.toFixed(0) : '-'}</div>
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1', marginTop: '15px' }}>
                    {translation.maxTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='100,200 0,400 200,400' style={{ fill: '#4485C6', stroke: '#4485C6', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#4485C6' }}>{normal.max != 0 ? normal.max : '-'}°C</font>
                </div>
                <hr style={{ height: '1px', width: '80%', zIndex: '1', margin: '0px auto', backgroundColor: '#4485C6', border: 'none' }} />
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.minTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='0,200 100,400 200,200' style={{ fill: '#82BDE7', stroke: '#82BDE7', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#82BDE7' }}>{normal.min != 100 ? normal.min : '-'}°C</font>
                </div>
            </div>
        </div>
    );

    const passTemperature = (
        <div className='card' style={{ width: '20%', minWidth: '300px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center' }}>{translation.passAverageTemp}</div>
            <div style={{ width: '250px', height: '110px', backgroundColor: '#78C6FF', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                <p style={{ width: '100px', margin: '30px 0px', color: '#FFFFFF', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '36px' }}>{translation.pass}</p>
                <div style={{ width: '150px', height: '110px', backgroundColor: '#FFFFFF', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flewFlow: 'row nowrap', justifyContent: 'center' }}>
                    <p style={{ margin: '18px 0px', color: '#469DFF', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '64px' }}>{normal.count ? normal.avg.toFixed(0) : '-'}</p>
                    <p style={{ margin: '25px 0px', color: '#53545C', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '32px' }}>°C</p>
                </div>
            </div>
            <div style={{ width: '250px', height: '110px', padding: '5px', backgroundColor: '#FFFFFF', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flewFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ height: '100%', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.minTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='0,200 100,400 200,200' style={{ fill: '#82BDE7', stroke: '#82BDE7', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                        <p style={{ margin: '6px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '44px' }}>{normal.count ? normal.min : '-'}</p>
                        <p style={{ margin: '10px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '22px' }}>°C</p>
                    </div>
                </div>
                <div className='vline'></div>
                <div style={{ height: '100%', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.maxTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='100,200 0,400 200,400' style={{ fill: '#4485C6', stroke: '#4485C6', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                        <p style={{ margin: '6px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '44px' }}>{normal.count ? normal.max : '-'}</p>
                        <p style={{ margin: '10px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '22px' }}>°C</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const newFailTemperature = (
        <div className='card' style={{ width: '20%', minWidth: '300px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center' }}>{translation.failAverageTemp}</div>
            <div style={{ width: '80%', height: '70%', border: '2px #E64644 solid', borderRadius: '15px', position: 'relative', backgroundColor: '#FFA8A880', overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start' }}>
                <div style={{ width: '125%', height: '50%', position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderRadius: '100% 100% 15px 15px', backgroundColor: '#FFFFFF' }}></div>
                <div style={{ height: '20px', fontSize: '25px', textAlign: 'center', color: '#E64644', fontFamily: 'Inter', fontWeight: 'bold', marginTop: '5px' }}>{translation.fail}</div>
                <div style={{ height: '10px', fontSize: '35px', textAlign: 'right', color: '#E64644', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%' }}>°C</div>
                <div style={{ height: '80px', fontSize: '90px', textAlign: 'center', color: '#E64644', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', margin: '0px' }}>{abnormal.avg ? abnormal.avg.toFixed(0) : '-'}</div>
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1', marginTop: '15px' }}>
                    {translation.maxTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='100,200 0,400 200,400' style={{ fill: '#E64644', stroke: '#E64644', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#E64644' }}>{abnormal.max != 0 ? abnormal.max : '-'}°C</font>
                </div>
                <hr style={{ height: '1px', width: '80%', zIndex: '1', margin: '0px auto', backgroundColor: '#E64644', border: 'none' }} />
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.minTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='0,200 100,400 200,200' style={{ fill: '#F0929C', stroke: '#F0929C', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#F0929C' }}>{abnormal.min != 100 ? abnormal.min : '-'}°C</font>
                </div>
            </div>
        </div>
    );

    const failTemperature = (
        <div className='card' style={{ width: '20%', minWidth: '300px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center' }}>{translation.failAverageTemp}</div>
            <div style={{ width: '250px', height: '110px', backgroundColor: '#FFA8A8', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                <p style={{ width: '100px', margin: '30px 0px', color: '#FFFFFF', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '36px' }}>{translation.fail}</p>
                <div style={{ width: '150px', height: '110px', backgroundColor: '#FFFFFF', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flewFlow: 'row nowrap', justifyContent: 'center' }}>
                    <p style={{ margin: '18px 0px', color: '#FF3838', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '64px' }}>{abnormal.count ? abnormal.avg.toFixed(0) : '-'}</p>
                    <p style={{ margin: '25px 0px', color: '#53545C', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '32px' }}>°C</p>
                </div>
            </div>
            <div style={{ width: '250px', height: '110px', padding: '5px', backgroundColor: '#FFFFFF', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '30px', display: 'flex', flewFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ height: '100%', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.minTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='0,200 100,400 200,200' style={{ fill: '#F0929C', stroke: '#F0929C', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                        <p style={{ margin: '6px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '44px' }}>{abnormal.count ? abnormal.min : '-'}</p>
                        <p style={{ margin: '10px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '22px' }}>°C</p>
                    </div>
                </div>
                <div className='vline'></div>
                <div style={{ height: '100%', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.maxTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='100,200 0,400 200,400' style={{ fill: '#E64644', stroke: '#E64644', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center' }}>
                        <p style={{ margin: '6px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '48px' }}>{abnormal.count ? abnormal.max : '-'}</p>
                        <p style={{ margin: '10px 0px', color: '#757575', textAlign: 'center', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: '600', fontSize: '24px' }}>°C</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const Temperature = (
        <div className='card' style={{ width: '45%', minWidth: '500px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center', margin: 'auto' }}>歷程平均溫度</div>
            <div style={{ width: '200px', height: '180px', border: '2px #4485C6 solid', borderRadius: '15px', position: 'relative', backgroundColor: '#80C9FF80', overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start' }}>
                <div style={{ width: '125%', height: '50%', position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderRadius: '100% 100% 15px 15px', backgroundColor: '#FFFFFF' }}></div>
                <div style={{ height: '20px', fontSize: '20px', textAlign: 'center', color: '#4485C6', fontFamily: 'Inter', fontWeight: 'bold', marginTop: '5px' }}>{translation.pass}</div>
                <div style={{ height: '10px', fontSize: '30px', textAlign: 'right', color: '#4485C6', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%' }}>°C</div>
                <div style={{ height: '80px', fontSize: '80px', textAlign: 'center', color: '#4485C6', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', margin: '0px' }}>{normal.avg ? normal.avg.toFixed(0) : '-'}</div>
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.maxTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='100,200 0,400 200,400' style={{ fill: '#4485C6', stroke: '#4485C6', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#4485C6' }}>{normal.max ? normal.max : '-'}°C</font>
                </div>
                <hr style={{ height: '1px', width: '80%', zIndex: '1', margin: '0px auto', backgroundColor: '#4485C6', border: 'none' }} />
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.minTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='0,200 100,400 200,200' style={{ fill: '#82BDE7', stroke: '#82BDE7', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#82BDE7' }}>{normal.min ? normal.min : '-'}°C</font>
                </div>
            </div>
            <div style={{ width: '200px', height: '180px', border: '2px #E64644 solid', borderRadius: '15px', position: 'relative', backgroundColor: '#FFA8A880', overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start' }}>
                <div style={{ width: '125%', height: '50%', position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderRadius: '100% 100% 15px 15px', backgroundColor: '#FFFFFF' }}></div>
                <div style={{ height: '20px', fontSize: '20px', textAlign: 'center', color: '#E64644', fontFamily: 'Inter', fontWeight: 'bold', marginTop: '5px' }}>{translation.fail}</div>
                <div style={{ height: '10px', fontSize: '30px', textAlign: 'right', color: '#E64644', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%' }}>°C</div>
                <div style={{ height: '80px', fontSize: '80px', textAlign: 'center', color: '#E64644', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', margin: '0px' }}>{abnormal.avg ? abnormal.avg.toFixed(0) : '-'}</div>
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.maxTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='100,200 0,400 200,400' style={{ fill: '#E64644', stroke: '#E64644', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#E64644' }}>{abnormal.max ? abnormal.max : '-'}°C</font>
                </div>
                <hr style={{ height: '1px', width: '80%', zIndex: '1', margin: '0px auto', backgroundColor: '#E64644', border: 'none' }} />
                <div style={{ height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1' }}>
                    {translation.minTemp}
                    <svg width='20px' height='20px' viewBox='-100 -100 400 550' style={{ zIndex: '1' }}>
                        <polygon className='triangle' strokeLinejoin='round' points='0,200 100,400 200,200' style={{ fill: '#F0929C', stroke: '#F0929C', strokeWidth: '100px', zIndex: '1' }} />
                    </svg>
                    <font style={{ color: '#F0929C' }}>{abnormal.min ? abnormal.min : '-'}°C</font>
                </div>
            </div>
        </div>
    );

    // const temperature = (
    //     <div style={{width: '500px', height: '250px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>
    //         <div style={{width: '500px', textAlign: 'center', margin: 'auto'}}>歷程平均溫度</div>
    //         <div style={{width: '200px', height: '180px', border: '2px #3F8EE8 solid', borderRadius: '15px', backgroundColor: '#80C9FF80', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center'}}>
    //             <div style={{height: '30px', fontSize: '20px', textAlign: 'center', color: '#53545C', fontFamily: 'Inter', fontWeight: 'bold'}}>正常</div>
    //             <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter'}}>最高溫度: 30°C</div>
    //             <div style={{height: '60px', fontSize: '40px', textAlign: 'center', color: '#3F8EE8', fontFamily: 'Roboto', fontWeight: 'bold'}}>25°C</div>
    //             <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter'}}>最低溫度: 20°C</div>
    //         </div>
    //         <div style={{width: '200px', height: '180px', border: '2px #FF3838 solid', borderRadius: '15px', backgroundColor: '#FFA8A880', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center'}}>
    //             <div style={{height: '30px', fontSize: '20px', textAlign: 'center', color: '#53545C', fontFamily: 'Inter', fontWeight: 'bold'}}>異常</div>
    //             <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter'}}>最高溫度: 30°C</div>
    //             <div style={{height: '60px', fontSize: '40px', textAlign: 'center', color: '#FF3838', fontFamily: 'Roboto', fontWeight: 'bold'}}>25°C</div>
    //             <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter'}}>最低溫度: 20°C</div>
    //         </div>
    //     </div>
    // )

    const information = (
        <div className='card' style={{ width: '45%', minWidth: '500px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden' }}>
            <div style={{ width: '100%', height: '20px', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center', margin: '20px 0px 10px 0px' }}>{translation.recentUpload}</div>
            <table style={{ width: '90%', borderCollapse: 'separate', borderSpacing: '0px 5px', margin: '0 auto' }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'left', borderBottom: '1px solid #E1E2E9' }}>{translation.time}</th>
                        <th style={{ textAlign: 'left', borderBottom: '1px solid #E1E2E9' }}>{translation.region}</th>
                        <th style={{ textAlign: 'left', borderBottom: '1px solid #E1E2E9' }}>{translation.company}</th>
                        <th style={{ textAlign: 'left', borderBottom: '1px solid #E1E2E9' }}>{translation.status}</th>
                    </tr>
                </thead>
                <tbody>
                    {uploadData.length ? (
                        uploadData.slice(0, 7).map((data) => {
                            return (
                                <tr key={data['time']}>
                                    {keys.map((key) => {
                                        if (key === 'status') {
                                            if (data[key] === true) {
                                                return (
                                                    <td key={`${data['time']}-${key}`}>
                                                        <div style={{ width: '70px', fontSize: '16px', padding: '3px', textAlign: 'center', backgroundColor: '#3F8EE829', borderRadius: '10px', color: '#3F8EE8' }}>{translation.pass}</div>
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td key={`${data['time']}-${key}`}>
                                                        <div style={{ width: '70px', fontSize: '16px', padding: '3px', textAlign: 'center', backgroundColor: '#FF383829', borderRadius: '10px', color: '#FF3838' }}>{translation.fail}</div>
                                                    </td>
                                                );
                                            }
                                        }
                                        return (
                                            <td key={`${data['time']}-${key}`} style={{ color: '#757575' }}>
                                                {data[key]}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        <tr></tr>
                    )}
                </tbody>
            </table>
        </div>
    );

    const newRatio = (
        <div className='card' style={{ width: '20%', minWidth: '300px', height: '300px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center' }}>{translation.errorRate}</div>
            <Progress errRate={errRate ? errRate.toFixed(2) * 100 : 0} />
        </div>
    );

    const ratio = (
        <div className='card' style={{ width: '45%', minWidth: '500px', height: '250px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
            {/*<div style={{width: '600px', height: '20px', textAlign: 'center', margin: 'auto'}}>上傳異常比例</div>*/}
            <div style={{ width: '100%', color: '#53545C', fontSize: '20px', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center', margin: '20px 0px 5px 0px' }}>上傳異常比例</div>
            <Plot
                data={[
                    // {
                    //     // domain: { x: [0, 0.5], y: [0, 1] },
                    //     domain: { row: 0, column: 0 },
                    //     value: 71,
                    //     number: { suffix: "%", font: { color: '#3F8EE8' } },
                    //     title: { text: "Percentage" },
                    //     type: 'indicator',
                    //     mode: 'gauge+number',
                    //     delta: { reference: 0 },
                    //     gauge: { axis: { range: [null, 100] }, bar: { color: "#3F8EE8" } }
                    // },
                    {
                        // domain: { x: [0.5, 1], y: [0, 1] },
                        domain: { row: 0, column: 1 },
                        value: errRate,
                        number: { suffix: '%', font: { color: '#FF3838' } },
                        title: { text: 'Percentage' },
                        type: 'indicator',
                        mode: 'gauge+number',
                        delta: { reference: 0 },
                        gauge: { axis: { range: [null, 100] }, bar: { color: '#FF3838' } },
                    },
                ]}
                layout={{
                    // title: '本週未通過比例',
                    // width: '500', height: '300',
                    grid: { columns: 1, rows: 1, pattern: 'independent' },
                    margin: { l: 50, r: 50, t: 50, b: 50, pad: 4 },
                }}
                config={{ staticPlot: true, responsive: true }}
                style={{ width: '100%', height: '90%' }}
            />
        </div>
    );

    // const newRatio = (
    //     <div className='card' style={{width: '45%', minWidth: '500px', height: '250px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>
    //         <Plot
    //             data={[
    //                 {
    //                     values: [71, 29],
    //                     labels: ['正常', '異常'],
    //                     hole: .7,
    //                     type: 'pie',
    //                     marker: {
    //                         colors: ['#3F8EE8', '#82BDE7']
    //                     }
    //                 }
    //             ]}
    //             layout={{title: '上傳異常比例', width: '500', height: '300', grid: {columns: 2, rows: 1, pattern: 'independent'}}}
    //         />
    //     </div>
    //     )

    return (
        <div style={{ display: 'flex', flex: 'auto', flexFlow: 'column nowrap', justifyContent: 'space-evenly' }}>
            <div style={{ display: 'flex', flex: 'auto', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
                <div className='row-plots'>
                    {newRatio}
                    {failTime}
                </div>
                <div className='row-plots'>
                    {newPassTemperature}
                    {newFailTemperature}
                </div>
                {/* <Popup trigger={quantity} position="right center" modal> */}
                {/*<div>每日上傳筆數</div>*/}
                {/*<div style={{width: '100%', margin: '10px', backgroundColor: '#FFFFFF', overflow: 'hidden'}}>*/}
                {/* <div style={{width: '500px', height: '20px', textAlign: 'center', margin: '30px auto'}}>每日上傳筆數</div>
                    <hr style={{width: '80%'}}></hr>
                    <Plot
                        data={[
                            {
                                type: 'bar',
                                name: 'abnormal',
                                marker: {
                                    color: '#F0929C',
                                    line: {
                                        color: '#E64644',
                                        width: 1.5
                                    }
                                },
                                tickformat: '%y-%d',
                                x: ['2022-10-14', '2022-10-15', '2022-10-16', '2022-10-17'],
                                y: [1, 3, 5, 2]
                            },
                            {
                                type: 'bar',
                                name: 'normal',
                                marker: {
                                    color: '#82BDE7',
                                    line: {
                                        color: '#4485C6',
                                        width: 1.5
                                    }
                                },
                                tickformat: '%y-%d',
                                x: ['2022-10-14', '2022-10-15', '2022-10-16', '2022-10-17'],
                                y: [2, 6, 3, 8]
                            }
                        ]}
                        layout={{
                            autosize: true,
                            barmode: 'stack',
                            xaxis: {
                                tickformat: '%m/%d',
                                dtick: '%d',
                            },
                        }}
                        config={{responsive: true, staticPlot: true}}
                        useResizeHandler
                        style={{width: '100%', height: '100%'}}
                    />
                    <Box width={"75%"} mx='auto' mb={"10px"}>
                        <Slider width={"75%"} defaultValue={50} aria-label="Default" valueLabelDisplay="auto"/>
                    </Box>
                    <FormControl sx={{width: 1}}>
                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{margin: 'auto'}}>Range</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{margin: 'auto'}}
                        >
                            <FormControlLabel value="hour" control={<Radio />} label="1 hour" />
                            <FormControlLabel value="day" control={<Radio />} label="1 day" />
                            <FormControlLabel value="month" control={<Radio />} label="1 month" />
                            <FormControlLabel value="months" control={<Radio />} label="6 months"/>
                        </RadioGroup>
                    </FormControl> */}

                {/*</div>*/}
                {/* </Popup>
                <Popup trigger={newTemperature} position="right center" modal> */}
                {/*<div>歷程平均溫度</div>*/}
                {/*<div style={{width: '100%', height: '100%', margin: '0px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>*/}
                {/* <div style={{width: '500px', textAlign: 'center', margin: '20px auto'}}>歷程平均溫度</div>
                    <hr style={{width: '80%'}}></hr>
                    <div style={{height: '80%', margin: '30px auto', display: 'flex', flexFlow: 'row', justifyContent: 'space-evenly'}}>
                        <div style={{width: '200px', height: '180px', border: '2px #4485C6 solid', borderRadius: '15px', position: 'relative', backgroundColor: '#80C9FF80', overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start'}}>
                            <div style={{width: '125%', height: '50%', position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderRadius: '100% 100% 15px 15px', backgroundColor: '#FFFFFF'}}></div>
                            <div style={{height: '20px', fontSize: '20px', textAlign: 'center', color: '#4485C6', fontFamily: 'Inter', fontWeight: 'bold', marginTop: '5px'}}>正常</div>
                            <div style={{height: '10px', fontSize: '30px', textAlign: 'right', color: '#4485C6', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%'}}>°C</div>
                            <div style={{height: '80px', fontSize: '80px', textAlign: 'center', color: '#4485C6', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', margin: '0px'}}>25</div>
                            <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1'}}>
                                最高溫
                                <svg width="20px" height="20px" viewBox="-100 -100 400 550" style={{zIndex: '1'}}>
                                    <polygon className="triangle" strokeLinejoin="round" points="100,200 0,400 200,400" style={{fill: '#4485C6', stroke: '#4485C6', strokeWidth: '100px', zIndex: '1'}}/>
                                </svg>
                                <font style={{color: '#4485C6'}}>30°C</font>
                            </div>
                            <hr style={{height: '1px', width: '80%', zIndex: '1', margin: '0px auto', backgroundColor: '#4485C6', border: 'none'}}/>
                            <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1'}}>
                                最低溫
                                <svg width="20px" height="20px" viewBox="-100 -100 400 550" style={{zIndex: '1'}}>
                                    <polygon className="triangle" strokeLinejoin="round" points="0,200 100,400 200,200" style={{fill: '#82BDE7', stroke: '#82BDE7', strokeWidth: '100px', zIndex: '1'}}/>
                                </svg>
                                <font style={{color: '#82BDE7'}}>20°C</font>
                            </div>
                        </div>
                        <div style={{width: '200px', height: '180px', border: '2px #E64644 solid', borderRadius: '15px', position: 'relative', backgroundColor: '#FFA8A880', overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'flex-start'}}>
                            <div style={{width: '125%', height: '50%', position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 0%)', borderRadius: '100% 100% 15px 15px', backgroundColor: '#FFFFFF'}}></div>
                            <div style={{height: '20px', fontSize: '20px', textAlign: 'center', color: '#E64644', fontFamily: 'Inter', fontWeight: 'bold', marginTop: '5px'}}>異常</div>
                            <div style={{height: '10px', fontSize: '30px', textAlign: 'right', color: '#E64644', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%'}}>°C</div>
                            <div style={{height: '80px', fontSize: '80px', textAlign: 'center', color: '#E64644', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', margin: '0px'}}>25</div>
                            <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1'}}>
                                最高溫
                                <svg width="20px" height="20px" viewBox="-100 -100 400 550" style={{zIndex: '1'}}>
                                    <polygon className="triangle" strokeLinejoin="round" points="100,200 0,400 200,400" style={{fill: '#E64644', stroke: '#E64644', strokeWidth: '100px', zIndex: '1'}}/>
                                </svg>
                                <font style={{color: '#E64644'}}>30°C</font>
                            </div>
                            <hr style={{height: '1px', width: '80%', zIndex: '1', margin: '0px auto', backgroundColor: '#E64644', border: 'none'}}/>
                            <div style={{height: '30px', fontSize: '15px', textAlign: 'center', color: '#757575', fontFamily: 'Inter', zIndex: '1'}}>
                                最低溫
                                <svg width="20px" height="20px" viewBox="-100 -100 400 550" style={{zIndex: '1'}}>
                                    <polygon className="triangle" strokeLinejoin="round" points="0,200 100,400 200,200" style={{fill: '#F0929C', stroke: '#F0929C', strokeWidth: '100px', zIndex: '1'}}/>
                                </svg>
                                <font style={{color: '#F0929C'}}>20°C</font>
                            </div>
                        </div>
                    </div>
                    <Box width={"75%"} mx='auto' mb={"30px"}>
                        <Slider width={"75%"} defaultValue={50} aria-label="Default" valueLabelDisplay="auto"/>
                    </Box> */}
                {/*<FormControl sx={{width: 1}}>*/}
                {/*    <FormLabel id="demo-row-radio-buttons-group-label" sx={{margin: 'auto'}}>Range</FormLabel>*/}
                {/*    <RadioGroup*/}
                {/*        row*/}
                {/*        aria-labelledby="demo-row-radio-buttons-group-label"*/}
                {/*        name="row-radio-buttons-group"*/}
                {/*        sx={{margin: 'auto'}}*/}
                {/*    >*/}
                {/*        <FormControlLabel value="hour" control={<Radio />} label="1 hour" />*/}
                {/*        <FormControlLabel value="day" control={<Radio />} label="1 day" />*/}
                {/*        <FormControlLabel value="month" control={<Radio />} label="1 month" />*/}
                {/*        <FormControlLabel value="months" control={<Radio />} label="6 months"/>*/}
                {/*    </RadioGroup>*/}
                {/*</FormControl>*/}
                {/*</div>*/}
                {/* </Popup> */}
            </div>
            <div style={{ display: 'flex', flex: 'auto', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
                {information}
                {quantity}
                {/* <Popup trigger={information} position="right center" modal>
                    <div style={{width: '100%', height: '500px', margin: '0px', borderRadius: '15px', backgroundColor: '#FFFFFF', overflowY: 'scroll', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>
                        <div style={{width: '500px', height: '20px', textAlign: 'center', margin: '30px auto'}}>近期上傳資訊</div>
                        <hr style={{width: '80%'}}></hr>
                        <table style={{width: '80%', margin: '10px', borderCollapse: 'collapse'}}>
                            <thead style={{overflosY: 'scroll'}}>
                            <tr>
                                {
                                    keys.map((key) => {
                                        return (
                                            <th style={{textAlign: 'left', borderBottom: '1px solid #E1E2E9'}}>{key}</th>
                                        );
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody style={{overflowY: 'scroll'}}>
                            {
                                uploadData.map((data) => {
                                    return (
                                        <tr>
                                            {
                                                keys.map(key => {
                                                    if (key === 'status') {
                                                        if (data[key] === 'normal') {
                                                            return (
                                                                <td><div style={{width: '50px', fontSize: '16px', padding: '3px', textAlign: 'center', backgroundColor: '#3F8EE829', borderRadius: '10px', color: '#3F8EE8'}}>正常</div></td>
                                                            );
                                                        }else {
                                                            return (
                                                                <td><div style={{width: '50px', fontSize: '16px', padding: '3px', textAlign: 'center', backgroundColor: '#FF383829', borderRadius: '10px', color: '#FF3838'}}>異常</div></td>
                                                            );
                                                        }
                                                    }
                                                    return (
                                                        <td style={{color: '#757575'}}>{data[key]}</td>
                                                    );
                                                })
                                            }
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </Popup>
                <Popup trigger={ratio} position="right center" modal> */}
                {/*<div style={{width: '500px', height: '250px', margin: '10px', borderRadius: '15px', backgroundColor: '#FFFFFF', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>*/}
                {/*<div style={{width: '600px', height: '20px', textAlign: 'center', margin: 'auto'}}>上傳異常比例</div>*/}
                {/* <div style={{width: '500px', height: '20px', textAlign: 'center', margin: '30px auto'}}>近期上傳資訊</div>
                    <hr style={{width: '80%'}}></hr>
                    <Plot
                        data={[
                            {
                                // domain: { x: [0, 0.5], y: [0, 1] },
                                domain: { row: 0, column: 0 },
                                value: 71,
                                number: { suffix: "%", font: { color: '#3F8EE8' } },
                                title: { text: "Percentage" },
                                type: 'indicator',
                                mode: 'gauge+number',
                                delta: { reference: 0 },
                                gauge: { axis: { range: [null, 100] }, bar: { color: "#3F8EE8" } }
                            },
                            {
                                // domain: { x: [0.5, 1], y: [0, 1] },
                                domain: { row: 0, column: 1 },
                                value: 81,
                                number: { suffix: "%", font: { color: '#FF3838' } },
                                title: { text: "Percentage" },
                                type: 'indicator',
                                mode: 'gauge+number',
                                delta: { reference: 0 },
                                gauge: { axis: { range: [null, 100] }, bar: { color: "#FF3838" } }
                            }
                        ]}
                        layout={{autosize: true, grid: {columns: 2, rows: 1, pattern: 'independent'}}}
                        config={{responsive: true, staticPlot: true}}
                        useResizeHandler
                        style={{width: '100%', height: '100%'}}
                    />
                    <Box width={"75%"} mx='auto' mb={"30px"}>
                        <Slider width={"75%"} defaultValue={50} aria-label="Default" valueLabelDisplay="auto"/>
                    </Box> */}
                {/*<FormControl sx={{width: 1}}>*/}
                {/*    <FormLabel id="demo-row-radio-buttons-group-label" sx={{margin: 'auto'}}>Range</FormLabel>*/}
                {/*    <RadioGroup*/}
                {/*        row*/}
                {/*        aria-labelledby="demo-row-radio-buttons-group-label"*/}
                {/*        name="row-radio-buttons-group"*/}
                {/*        sx={{margin: 'auto'}}*/}
                {/*    >*/}
                {/*        <FormControlLabel value="hour" control={<Radio />} label="1 hour" />*/}
                {/*        <FormControlLabel value="day" control={<Radio />} label="1 day" />*/}
                {/*        <FormControlLabel value="month" control={<Radio />} label="1 month" />*/}
                {/*        <FormControlLabel value="months" control={<Radio />} label="6 months"/>*/}
                {/*    </RadioGroup>*/}
                {/*</FormControl>*/}
                {/*</div>*/}
                {/* </Popup> */}
            </div>
        </div>
    );
}

export default DataPlot;
