import { useEffect, useRef, useState } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { getReportData } from '../api';
import { ReactComponent as Cross } from '../images/cross.svg';
import { ReactComponent as DownloadIcon } from '../images/download-center.svg';
import { ReactComponent as Help } from '../images/help.svg';
import logo from '../images/logo-login.png';
import './Download.css';
import Cert from './components/Cert';
import CustomInput from './components/CustomInput';
import Report from './components/Report';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 10,
        padding: '10px 5px',
    },
}));

function Download() {
    const urlParams = new URLSearchParams(window.location.search);

    const [deviceID, setDeviceID] = useState(urlParams.get('device_id') || '');
    const [index, setIndex] = useState(parseInt(urlParams.get('index')) || -1);
    const [inputValue, setInputValue] = useState(urlParams.get('device_id') || '');
    const [reportData, setReportData] = useState({});
    const [certData, setCertData] = useState({});
    const [page, setPage] = useState(deviceID ? 2 : 1);
    const [lockInput, setLockInput] = useState(false);
    const [lockReport, setLockReport] = useState(true);
    const [lockCert, setLockCert] = useState(true);

    const [preparing, setPreparing] = useState(false);
    const [message, setMessage] = useState('');

    const reportRef = useRef();
    const certRef = useRef();
    const errorRef = useRef();

    useEffect(() => {
        if (deviceID !== '') {
            setMessage('Loading data...');
            setPreparing(true);
            getReportData(deviceID, index).then((data) => {
                console.log('data', data.body);
                if (Object.keys(data.body).length > 0) {
                    if (data.body.calibration_points) {
                        const timeOffset = dayjs().utcOffset();
                        setCertData({
                            id: data.body.device_id,
                            calibrationPoints: data.body.calibration_points,
                            calibrationTime: dayjs(data.body.calibration_time).add(timeOffset, 'm').format('YYYY-MM-DD'),
                            expirationTime: dayjs(data.body.expire_time).add(timeOffset, 'm').format('YYYY-MM-DD'),
                            model_info: data.body.model_info,
                        });
                        setLockCert(false);
                    }
                    if (data.body.temps) {
                        // todo: use predict_missed to fill in missing data
                        const now = dayjs();
                        const timeOffset = now.utcOffset();
                        const start_time = data.body.start_time;
                        // const length = detailRowData.temps.length;
                        const dataList = data.body.temps.map((item, index) => {
                            return {
                                time: dayjs(start_time)
                                    .add(timeOffset, 'm')
                                    .add(index * 10, 'm')
                                    .format('YYYY-MM-DD HH:mm'),
                                temperature: item,
                            };
                        });

                        setReportData({
                            id: data.body.device_id,
                            startTime: dayjs(data.body.start_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'),
                            endTime: dayjs(data.body.end_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'),
                            number_of_record: dataList.length,
                            interval: data.body.interval,
                            createTime: data.body.upload_time,
                            shipper: data.body.meta_data.shipper_name,
                            is_normal: data.body.is_normal,
                            track_number: data.body.meta_data.shipment_number,
                            timeZone: dayjs(now).format('UTCZ'),
                            detailRowData: dataList,
                            upperLimit: data.body.threshold.upper_limit,
                            lowerLimit: data.body.threshold.lower_limit,
                            timeLimit: data.body.threshold.time_limit,
                            thresholdType: data.body.threshold.type === 'continuous' ? 'Single Event' : 'Cumulative',
                            model_info: data.body.model_info,
                        });
                        setLockReport(false);
                    }
                    errorRef.current.style.visibility = 'hidden';
                    setLockInput(true);
                    setPage(2);
                } else {
                    errorRef.current.style.visibility = 'visible';
                }
                setPreparing(false);
            });
        }
    }, [deviceID]);

    function handleConfirm() {
        setDeviceID(inputValue);
        setIndex(-1);
    }

    function handlePrev() {
        setPage(1);
        setLockInput(false);
        setLockReport(true);
        setLockCert(true);
        setDeviceID('');
    }

    const createReport = async () => {
        // let input = document.getElementsByClassName('graph')[0];
        // selected.forEach((id) => {
        // console.log('reportRef', reportRef);
        setMessage(`Preparing to download Data-report-${deviceID}.pdf`);
        setPreparing(true);
        const element = reportRef.current;
        setTimeout(() => {
            html2canvas(element, {
                // onclone: (document) => {
                //     document.getElementById("report").style.display = "block";
                // }
            }).then((canvas) => {
                const pdf = new jsPDF('portrait', 'pt', 'a4');
                const width = 595;
                const pageHeight = 842;
                const imgHeight = (canvas.height * width) / canvas.width;
                // console.log('canvas height width', imgHeight);
                let remain = imgHeight;
                let position = 0;
                remain -= pageHeight;
                let imgData = canvas.toDataURL('image/png', 1.0);
                pdf.addImage(imgData, 'PNG', 0, 0, width, imgHeight, '', 'FAST');
                // pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                while (remain >= 0) {
                    position = remain - imgHeight;
                    // console.log('position', position)
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                    // pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                    remain -= pageHeight;
                }
                pdf.save(`Data-report-${deviceID}.pdf`);
                setPreparing(false);
            });
        }, 500);
        // const pages = document.getElementById('report');
        // });
    };

    const createCert = async () => {
        // let input = document.getElementsByClassName('graph')[0];
        // selected.forEach((id) => {
        setMessage(`Preparing to download Calibration-certificate-${deviceID}.pdf`);
        setPreparing(true);
        const element = certRef.current;
        setTimeout(() => {
            html2canvas(element, {
                // onclone: (document) => {
                //     document.getElementById("report").style.display = "block";
                // }
            }).then((canvas) => {
                const pdf = new jsPDF('portrait', 'pt', 'a4');
                const width = 595;
                const pageHeight = 842;
                const imgHeight = (canvas.height * width) / canvas.width;
                // console.log('canvas height width', imgHeight);
                let remain = imgHeight;
                let position = 0;
                remain -= pageHeight;
                let imgData = canvas.toDataURL('image/png', 1.0);
                pdf.addImage(imgData, 'PNG', 0, 0, width, imgHeight, '', 'FAST');
                // pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                // while (remain >= 0) {
                //     position = remain - imgHeight;
                //     // console.log('position', position)
                //     pdf.addPage();
                //     pdf.addImage(imgData, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                //     // pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                //     remain -= pageHeight;
                // }
                pdf.save(`Calibration-certificate-${deviceID}.pdf`);
                setPreparing(false);
            });
        }, 500);
        // const pages = document.getElementById('report');
        // });

        // });
    };

    function redirHome() {
        window.location.href = 'https://gocochain.com';
    }

    return (
        <div className='download-card-background'>
            <img
                className='logo'
                alt='logo'
                src={logo}
                style={{
                    width: '140px',
                    height: '32px',
                    paddingRight: '300px',
                    backgroundColor: '#F323423',
                }}
                onClick={redirHome}
            />
            <div className='download-card'>
                <div className='title-area'>
                    <button
                        className='left'
                        onClick={handlePrev}
                        style={{
                            float: 'left',
                            visibility: page === 2 ? 'visible' : 'hidden',
                            position: 'absolute',
                            left: '2rem',
                        }}
                    >
                        <KeyboardArrowLeftIcon style={{ fontSize: '1.5rem' }} />
                    </button>
                    <DownloadIcon className='download-icon' />
                    <div>
                        <p
                            style={{
                                margin: '0',
                                fontSize: '1.5rem',
                                fontWeight: '900',
                            }}
                        >
                            Download Center
                        </p>
                        <p
                            style={{
                                margin: '0',
                                fontSize: '0.75rem',
                                fontWeight: '400',
                                color: '#ADABAB',
                                width: '13rem',
                            }}
                        >
                            Get your data reports or calibration certificates of Gocochain devices.
                        </p>
                    </div>
                </div>
                <div style={{ marginTop: '3rem' }}>
                    <p style={{ fontSize: '1.25rem', fontWeight: '700' }}>
                        Device ID{' '}
                        <LightTooltip title={'Enter Device ID on the back of your CoTag'} placement='top'>
                            <Help
                                style={{
                                    verticalAlign: 'middle',
                                    width: '1rem',
                                }}
                            />
                        </LightTooltip>
                    </p>
                </div>

                <CustomInput setInputValue={setInputValue} lock={lockInput} handleConfirm={handleConfirm} />

                <div ref={errorRef} style={{ visibility: 'hidden' }}>
                    <p style={{ color: '#FF3838' }}>
                        <Cross style={{ height: '0.7rem' }} /> Please enter the right Device ID
                    </p>
                </div>

                <div>
                    <button
                        className='device-id'
                        style={{
                            width: '9.25rem',
                            display: page === 1 ? 'block' : 'none',
                            marginTop: '3rem',
                        }}
                        onClick={handleConfirm}
                    >
                        Confirm
                    </button>
                    <button
                        className='device-id'
                        style={{
                            width: '20rem',
                            margin: '1rem 0',
                            display: page === 2 ? 'block' : 'none',
                        }}
                        disabled={lockReport}
                        onClick={createReport}
                    >
                        Download Data Report
                    </button>
                    <button
                        className='device-id'
                        style={{
                            width: '20rem',
                            margin: '1rem 0',
                            display: page === 2 ? 'block' : 'none',
                        }}
                        disabled={lockCert}
                        onClick={createCert}
                    >
                        {' '}
                        Download Calibration Certificate
                    </button>
                </div>

                <div style={{ position: 'absolute', bottom: '0' }}>
                    <p>
                        Questions? contact <a href='mailto: support@gocochain.com'>support@gocochain.com</a>
                    </p>
                </div>
            </div>
            <p
                style={{
                    position: 'absolute',
                    bottom: '0',
                    minWidth: '1000px',
                    textAlign: 'center',
                }}
            >
                © 2024 Gocochain Co., Ltd. All rights reserved.
            </p>
            <Snackbar
                open={preparing}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{
                    backgroundColor: '#333333',
                    color: '#FFFFFF',
                    padding: '0 1rem',
                    margin: '0.8rem auto',
                    position: 'absolute',
                    maxWidth: '20rem',
                    breakPoint: 'xxs',
                }}
            >
                <p>
                    <CircularProgress size='1rem' style={{ verticalAlign: 'text-top' }} /> {message}
                </p>
            </Snackbar>

            {lockReport ? (
                <></>
            ) : (
                <div
                    id='report'
                    ref={reportRef}
                    style={{
                        display: 'block',
                        width: '595pt',
                        position: 'absolute',
                        left: '-1000px',
                    }}
                >
                    <Report reportData={reportData} />
                </div>
            )}
            {lockCert ? (
                <></>
            ) : (
                <div
                    id='cert'
                    ref={certRef}
                    style={{
                        display: 'block',
                        width: '595pt',
                        position: 'absolute',
                        left: '-1000px',
                    }}
                >
                    <Cert certData={certData} />
                </div>
            )}
        </div>
    );
}

export default Download;
