import * as React from 'react';
import { useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { getNRecords, getRecordById, updateRecord } from '../api.js';
import { ReactComponent as ArrowLeft } from '../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../images/arrow-right.svg';
import { ReactComponent as CsvIcon } from '../images/csv.svg';
import { ReactComponent as PdfIcon } from '../images/pdf.svg';
import generateExcel from '../lib/excel.js';
import { predict_missed } from '../lib/predict.js';
import './Records.css';
import Configure from './components/Configure.js';
import Detail from './components/Detail.js';
import Edit from './components/Edit.js';
import Report from './components/Report.js';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 11,
    },
}));

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 260,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

function createData(translation, num, id, createTime, startTime, endTime, length, interval, timeZone, company, status, trackNum, note, deviceId, index, lowerLimit, upperLimit, timeLimit, totalMinutes, thresholdType) {
    let durationTime = Math.floor((length * interval) / 60);
    let durationMin = durationTime % 60;
    let durationHour = Math.floor(durationTime / 60) % 24;
    let durationDay = Math.floor(durationTime / 60 / 24);
    let duration = `${durationDay} ${translation.D} ${durationHour} ${translation.H} ${durationMin} ${translation.M}`;

    return {
        num,
        id,
        createTime,
        startTime,
        endTime,
        length,
        duration,
        interval,
        timeZone,
        company,
        status,
        trackNum,
        note,
        deviceId,
        index,
        lowerLimit,
        upperLimit,
        timeLimit,
        totalMinutes,
        thresholdType,
    };
}

function createDataChildren(time, temperature) {
    return { time, temperature };
}

function Row(props) {
    const { row, isItemSelected, onClick } = props;
    const [open, setOpen] = React.useState(false);
    const [bgColor, setBgColor] = React.useState(row.num % 2 ? '#FFFFFF' : '#F3F5F9');

    const [editShipper, setEditShipper] = React.useState(false);
    const [editTrackNum, setEditTrackNum] = React.useState(false);
    const [editNote, setEditNote] = React.useState(false);
    const companyInput = React.useRef(null);
    const trackNumInput = React.useRef(null);
    const noteInput = React.useRef(null);

    const [details, setDetails] = React.useState([]);
    const [minTemp, setMinTemp] = React.useState(100);
    const [maxTemp, setMaxTemp] = React.useState(-100);

    const [metaData, setMetaData] = React.useState({});
    const [threshold, setThreshold] = React.useState({});
    const [interval, setInterval] = React.useState(10);

    const [switchPage, setSwitchPage] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { translation } = props;

    useEffect(() => {
        // const getDetails = async () => {
        //     const data = await getRecordById(row.id);
        //     setDetails(data.rows);
        // }
        // getDetails();
        setDetails([]);
        setOpen(false);

        // getRecordById(row.id).then((res) => {
        //     setDetails(res.data.rows.map((item) => createDataChildren(dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'), item.temperature)));
        // });
    }, [row]);

    const onEditShipper = () => {
        // console.log('editClick', row.id)
        setEditShipper(true);
    };

    const onSaveShipper = () => {
        // console.log('saveClick', row.id)
        // console.log('companyInput', companyInput.current.value)
        updateRecord(row.deviceId, row.index, {
            shipper: companyInput.current.value,
        }).then((res) => {
            // console.log(res)
        });
        row.company = companyInput.current.value;
        setEditShipper(false);
    };

    const onEditTrackNum = () => {
        // console.log('editClick', row.id)
        setEditTrackNum(true);
    };

    const onSaveTrackNum = () => {
        // console.log('saveClick', row.id)
        // console.log('trackNumInput', trackNumInput.current.value)
        updateRecord(row.deviceId, row.index, {
            track_number: trackNumInput.current.value,
        }).then((res) => {
            // console.log(res)
        });
        row.trackNum = trackNumInput.current.value;
        setEditTrackNum(false);
    };

    const onEditNote = () => {
        // console.log('editClick', row.id)
        setEditNote(true);
    };

    const onSaveNote = () => {
        // console.log('saveClick', row.deviceId)
        // console.log('noteInput', noteInput.current.value)
        updateRecord(row.deviceId, row.index, {
            note: noteInput.current.value,
        }).then((res) => {
            // console.log(res)
        });
        row.note = noteInput.current.value;
        setEditNote(false);
    };

    const onSwitchPage = () => {
        setSwitchPage(!switchPage);
    };

    const onExpand = () => {
        if (details.length === 0 && !open) {
            setLoading(true);
            setOpen(!open);
            getRecordById(row.deviceId, row.index).then((res) => {
                let temps = res.body.temps;
                temps = predict_missed(temps);
                // time zone offset solve when first retrieve data
                // const now = dayjs();
                // const timeOffset = now.utcOffset();
                let minTempL = Math.min(...temps, 100);
                let maxTempL = Math.max(...temps, -100);
                setMetaData(res.body.meta_data);
                setThreshold(res.body.threshold);
                setInterval(res.body.interval);
                setDetails(
                    temps.map((item, index) => {
                        // if (item.temperature !== null && item.temperature > maxTempL) {
                        //     maxTempL = item.temperature;
                        // }
                        // if (item.temperature !== null && item.temperature < minTempL) {
                        //     minTempL = item.temperature;
                        // }

                        return createDataChildren(
                            dayjs(row.startTime)
                                .add(index * row.interval, 's')
                                .format('YYYY-MM-DD HH:mm'),
                            item
                        );
                    })
                );
                setMinTemp(minTempL);
                setMaxTemp(maxTempL);
                setLoading(false);
            });
        } else {
            setOpen(!open);
        }
    };

    // const abnormalDrawX = details.length > 432 ? details.filter((row) => row.temperature > row.upperBound || row.temperature < row.lowerBound).filter((row, index) => index % 6 === 0).map((row) => row.time) : details.filter((row) => row.temperature > row.upperBound || row.temperature < row.lowerBound).map((row) => row.time);
    // const abnormalDrawY = details.length > 432 ? details.filter((row) => row.temperature > row.upperBound || row.temperature < row.lowerBound).filter((row, index) => index % 6 === 0).map((row) => row.temperature) : details.filter((row) => row.temperature > row.upperBound || row.temperature < row.lowerBound).map((row) => row.temperature);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: bgColor }}>
                <TableCell padding='checkbox' style={{ borderBottom: 'none' }}>
                    <Checkbox
                        size='small'
                        checked={isItemSelected}
                        onClick={(event) => onClick(event, row.id)}
                        inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${row.id}`,
                        }} //What is this?
                    />
                </TableCell>
                <TableCell component='th' scope='row' style={{ borderBottom: 'none' }}>
                    {row.num}
                </TableCell>
                <TableCell align='left' style={{ borderBottom: 'none' }}>
                    {row.status === true ? (
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <span style={{ color: '#757575' }}>{translation.failAlarm}</span> <br />
                                    {row.thresholdType == 'continuous' ? translation.continuous : translation.cumulative}: {translation.outof} {row.lowerLimit}°C {translation.to} {row.upperLimit}°C {translation.range}
                                    {translation.for} {row.timeLimit / 60} {translation.minutes}
                                </React.Fragment>
                            }
                            placement='top'
                        >
                            <div style={{ width: '50px', fontSize: '16px', padding: '3px', textAlign: 'center', backgroundColor: '#3F8EE829', borderRadius: '10px', color: '#3F8EE8' }}>{translation.pass}</div>
                        </HtmlTooltip>
                    ) : (
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <span style={{ color: '#757575' }}>{translation.failAlarm}</span> <br />
                                    {row.thresholdType == 'continuous' ? translation.continuous : translation.cumulative}: {translation.outof} {row.lowerLimit}°C {translation.to} {row.upperLimit}°C {translation.range}
                                    {translation.for} {row.timeLimit / 60} {translation.minutes}
                                </React.Fragment>
                            }
                            placement='top'
                        >
                            <div style={{ width: '50px', fontSize: '16px', padding: '3px', textAlign: 'center', backgroundColor: '#FF383829', borderRadius: '10px', color: '#FF3838' }}>{translation.fail}</div>
                        </HtmlTooltip>
                    )}
                </TableCell>
                <TableCell align='left' style={{ borderBottom: 'none' }}>
                    {row.deviceId}
                </TableCell>
                <TableCell align='left' style={{ borderBottom: 'none' }}>
                    {row.startTime} {translation.to} {row.endTime}
                </TableCell>
                <TableCell align='left' style={{ borderBottom: 'none' }}>
                    {row.length}
                </TableCell>
                <TableCell align='left' style={{ borderBottom: 'none' }}>
                    {row.duration}
                </TableCell>
                {/* <TableCell align="left" style={{borderBottom:"none"}}>{row.timeZone}</TableCell> */}
                {/* <TableCell align="left" style={{borderBottom:"none"}}>{editShipper ? <input ref={companyInput} defaultValue={row.company} style={{width: '100px', height: '22px', fontSize: '14px', marginRight: '3px'}}></input> : row.company ? row.company : <span style={{color: '#757575'}}>例：黑貓</span>}{editShipper ? <Button variant="contained" sx={{width: '10px', height: '25px'}} onClick={onSaveShipper}>確認</Button> : <IconButton onClick={onEditShipper}><EditIcon sx={{fontSize: '15px'}}/></IconButton>}</TableCell> */}
                {/* <TableCell align="left" style={{borderBottom:"none"}}>{editTrackNum ? <input ref={trackNumInput} defaultValue={row.trackNum} style={{width: '100px', height: '22px', fontSize: '14px', marginRight: '3px'}}></input> : row.trackNum ? row.trackNum : <span style={{color: '#757575'}}>例：123456789</span>}{editTrackNum ? <Button variant="contained" sx={{width: '10px', height: '25px'}} onClick={onSaveTrackNum}>確認</Button> : <IconButton onClick={onEditTrackNum}><EditIcon sx={{fontSize: '15px'}}/></IconButton>}</TableCell> */}
                <TableCell align='left' style={{ borderBottom: 'none' }}>
                    {editNote ? <input ref={noteInput} defaultValue={row.note} style={{ width: '100px', height: '22px', fontSize: '14px', marginRight: '3px' }}></input> : row.note ? row.note : <span style={{ color: '#757575' }}>{translation.note}</span>}
                    {editNote ? (
                        <Button variant='contained' sx={{ width: '100px', height: '25px' }} onClick={onSaveNote}>
                            { translation.confirm }
                        </Button>
                    ) : (
                        <IconButton onClick={onEditNote}>
                            <EditIcon sx={{ fontSize: '15px' }} />
                        </IconButton>
                    )}
                </TableCell>

                <TableCell style={{ borderBottom: 'none' }}>
                    <IconButton aria-label='expand row' size='small' onClick={onExpand}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>

            {/* { open ? DetailRow({bgColor, details, setDetails}) : null } */}
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: bgColor }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }} colSpan={10}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <div style={{ height: '550px', display: 'flex', flexFlow: 'row nowrap' }}>
                            <button className='switch' onClick={onSwitchPage}>
                                <ArrowLeft />
                            </button>
                            <Box style={{ width: '100%', display: 'flex', margin: 1, flexFlow: 'row nowrap', alignItem: 'center', justifyContent: 'space-around' }}>{loading ? <CircularProgress /> : switchPage ? <Configure deviceId={row.deviceId} index={row.index} metaData={metaData} threshold={threshold} interval={interval} translation={translation.Edit} ro={true} /> : <Detail details={details} row={row} translation={translation} maxTemp={maxTemp} minTemp={minTemp} bgColor={bgColor} />}</Box>
                            <button className='switch' onClick={onSwitchPage}>
                                <ArrowRight />
                            </button>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

// Row.propTypes = {
//     row: PropTypes.shape({
//         id: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//         length: PropTypes.number.isRequired,
//         timeZone: PropTypes.string.isRequired,
//         company: PropTypes.string.isRequired,
//         status: PropTypes.string.isRequired,
//         trackNum: PropTypes.number.isRequired,
//         deviceId: PropTypes.string.isRequired,
//         lowerLimit: PropTypes.number.isRequired,
//         upperLimit: PropTypes.number.isRequired,
//         totalMinutes: PropTypes.number.isRequired,
//         thresholdType: PropTypes.string.isRequired,
//     }).isRequired,
// };

function Records(props) {
    const [selected, setSelected] = React.useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState([]);
    const [startTime, setStartTime] = React.useState(dayjs().subtract(3, 'month'));
    const [endTime, setEndTime] = React.useState(dayjs());
    const [searchStartTime, setSearchStartTime] = React.useState(dayjs().subtract(3, 'month'));
    const [searchEndTime, setSearchEndTime] = React.useState(dayjs());
    // const [filter, setFilter] = React.useState(false);

    const [records, setRecords] = React.useState([]);
    const [value, setValue] = React.useState([null, null]);

    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(0);
    const [recordCount, setRecordCount] = React.useState(0);

    const [reportData, setReportData] = React.useState({});

    const [openConfig, setOpenConfig] = React.useState(false);

    const [saveAlert, setSaveAlert] = React.useState(false);

    const [searchString, setSearchString] = React.useState('');

    const [exportName, setExportName] = React.useState('');
    const [preparing, setPreparing] = React.useState(false);

    const { translation } = props;

    const printRef = React.useRef();

    useEffect(() => {
        const now = dayjs();
        const timeOffset = now.utcOffset();
        getNRecords((page - 1) * limit, limit, dayjs(searchStartTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00'), dayjs(searchEndTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00')).then((data) => {
            // console.log('getrecords', data);
            // setFilter(false);

            data = data.records.map((record, index) => {
                // return createData(record.record_id, record.create_time, record.length, record.region, record.company, record.status, record.trackNum, record.deviceId)
                // solve time zone problem
                return createData(translation, index + 1, `${record.device_id}-${record.index}`, dayjs(record.create_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), dayjs(record.start_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), dayjs(record.end_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), record.number_of_records, record.interval, dayjs(now).format('UTCZ'), record.meta_data.shipper_name, record.is_normal, record.meta_data.shipment_number, record.meta_data.note, record.device_id, record.index, record.threshold.lower_limit, record.threshold.upper_limit, record.threshold.time_limit, record.total_minute, record.threshold.type);
            });
            data = filterRecords(data, searchString);
            setRecordCount(data.length);
            setPageCount(Math.ceil(data.length / 10));
            setRecords(data);
        });
    }, [searchEndTime]);

    const filterRecords = (records, keyword) => {
        if (keyword === '') {
            return records;
        }
        return records.filter((record) => {
            return record.deviceId ? record.deviceId.includes(keyword) : false;
            // || (record.company ? record.company.includes(keyword) : false)
            // || (record.trackNum ? record.trackNum.toString().includes(keyword) : false)
            // || (record.status ? record.status.toString().includes(keyword) : false)
            // || (record.length ? record.length.toString().includes(keyword) : false)
            // || (record.timeZone ? record.timeZone.includes(keyword) : false)
            // || (record.startTime ? record.startTime.includes(keyword) : false)
            // || (record.endTime ? record.endTime.includes(keyword) : false);
        });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // get only the records on the current page
            setSelected(records.slice((page - 1) * limit, page * limit).map((n) => n.id));
            setSelectedDeviceId(records.slice((page - 1) * limit, page * limit).map((n) => n.deviceId));
            setSelectedIndex(records.slice((page - 1) * limit, page * limit).map((n) => n.index));
            return;
        }
        setSelected([]);
        setSelectedDeviceId([]);
        setSelectedIndex([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        setSelectedDeviceId(newSelected.map((id) => records.find((record) => record.id === id).deviceId));
        setSelectedIndex(newSelected.map((id) => records.find((record) => record.id === id).index));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setSelected([]);
        setSelectedDeviceId([]);
        setSelectedIndex([]);
    };

    const handleSearch = (event) => {
        // setSearch(event.target.value);
        setSearchStartTime(dayjs(startTime));
        setSearchEndTime(dayjs(endTime));
        // setFilter(true);
        setPage(1);
    };

    const handleCloseSaveAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveAlert(false);
    };

    const createExcel = async () => {
        selected.forEach((id) => {
            let rowData = records.find((record) => record.id === id);
            setExportName(`Data-report-${rowData.deviceId}.xlsx`);
            setPreparing(true);
            getRecordById(rowData.deviceId, rowData.index).then(async (data) => {
                // console.log('detail row data', detailRowData);

                // todo: use predict_missed to fill the missing data
                const now = dayjs();
                const timeOffset = now.utcOffset();
                const start_time = rowData.startTime;
                // const length = detailRowData.temps.length;
                let temps = data.body.temps;
                temps = predict_missed(temps);
                const dataList = temps.map((item, index) => {
                    return {
                        time: dayjs(start_time)
                            .add(index * 10, 'm')
                            .format('YYYY-MM-DD HH:mm'),
                        temperature: item,
                    };
                });

                const excelData = {
                    id: rowData.deviceId,
                    startTime: rowData.startTime,
                    endTime: rowData.endTime,
                    number_of_record: rowData.length,
                    interval: rowData.interval,
                    createTime: rowData.createTime,
                    shipper: rowData.company,
                    is_normal: rowData.status,
                    track_number: rowData.trackNumber,
                    timeZone: rowData.timeZone,
                    detailRowData: dataList,
                    upperLimit: rowData.upperLimit,
                    lowerLimit: rowData.lowerLimit,
                    timeLimit: rowData.timeLimit,
                    thresholdType: rowData.thresholdType === 'continuous' ? 'Single Event' : 'Cumulative',
                    meta_data: data.body.meta_data,
                    model_info: data.body.model_info,
                };

                generateExcel(excelData);
                setPreparing(false);
            });
        });
    };

    const createPDF = async () => {
        // let input = document.getElementsByClassName('graph')[0];

        selected.forEach((id) => {
            let rowData = records.find((record) => record.id === id);
            setExportName(`Data-report-${rowData.deviceId}.pdf`);
            setPreparing(true);

            getRecordById(rowData.deviceId, rowData.index).then(async (data) => {
                // console.log('detail row data', detailRowData);
                // todo: use predict_missed to fill the missing data
                const now = dayjs();
                const timeOffset = now.utcOffset();
                const start_time = rowData.startTime;
                // const length = detailRowData.temps.length;
                let temps = data.body.temps;
                temps = predict_missed(temps);
                const dataList = temps.map((item, index) => {
                    return {
                        time: dayjs(start_time)
                            .add(index * 10, 'm')
                            .format('YYYY-MM-DD HH:mm'),
                        temperature: item,
                    };
                });

                setReportData({
                    id: rowData.deviceId,
                    startTime: rowData.startTime,
                    endTime: rowData.endTime,
                    number_of_record: rowData.length,
                    interval: rowData.interval,
                    createTime: rowData.createTime,
                    shipper: rowData.company,
                    is_normal: rowData.status,
                    track_number: rowData.trackNumber,
                    timeZone: rowData.timeZone,
                    detailRowData: dataList,
                    upperLimit: rowData.upperLimit,
                    lowerLimit: rowData.lowerLimit,
                    timeLimit: rowData.timeLimit,
                    thresholdType: rowData.thresholdType === 'continuous' ? 'Single Event' : 'Cumulative',
                    model_info: data.body.model_info,
                });

                const element = printRef.current;
                // console.log('pdf', element)
                setTimeout(() => {
                    html2canvas(element, {
                        // onclone: (document) => {
                        //     document.getElementById("report").style.display = "block";
                        // }
                    }).then((canvas) => {
                        const pdf = new jsPDF('portrait', 'pt', 'a4');
                        const width = 595;
                        const pageHeight = 842;
                        const imgHeight = (canvas.height * width) / canvas.width;
                        // console.log('canvas height width', imgHeight);
                        let remain = imgHeight;
                        let position = 0;
                        remain -= pageHeight;
                        let imgData = canvas.toDataURL('image/png', 1.0);
                        pdf.addImage(imgData, 'PNG', 0, 0, width, imgHeight, '', 'FAST');
                        // pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                        while (remain >= 0) {
                            position = remain - imgHeight;
                            // console.log('position', position)
                            pdf.addPage();
                            pdf.addImage(imgData, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                            // pdf.addImage(canvas, 'PNG', 0, position, width, imgHeight, '', 'FAST');
                            remain -= pageHeight;
                        }
                        pdf.save(`Data-report-${rowData.deviceId}.pdf`);
                        setPreparing(false);
                    });
                }, 500);
                const pages = document.getElementById('report');
            });
        });
    };

    return (
        <div
            style={{
                width: '95%',
                minHeight: '100vh',
                paddingBottom: '20px',
                margin: '32px auto 0 auto',
            }}
        >
            <Snackbar open={saveAlert} autoHideDuration={6000} onClose={handleCloseSaveAlert}>
                <Alert onClose={handleCloseSaveAlert} severity='success'>
                    Save successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={preparing}
                archorigin={{ vertical: 'bottom', horizontal: 'left' }}
                sx={{
                    backgroundColor: '#333333',
                    color: '#FFFFFF',
                    padding: '0 1rem',
                }}
            >
                <p>
                    <CircularProgress size='1rem' style={{ verticalAlign: 'text-top' }} /> Preparing to download {exportName}
                </p>
            </Snackbar>

            <div style={{ height: '100px', padding: '0px 30px', display: 'flex', borderRadius: '16px', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: '#FFFFFF' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={translation.startTime}
                        value={startTime}
                        onChange={(newTime) => {
                            setStartTime(newTime);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                sx={{
                                    width: '175px',
                                    marginLeft: '0px',
                                    marginRight: '0px',
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={translation.endTime}
                        value={endTime}
                        onChange={(newTime) => {
                            setEndTime(newTime);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                sx={{
                                    width: '175px',
                                    marginLeft: '0px',
                                    marginRight: '47px',
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
                <TextField label='' variant='outlined' InputProps={{ sx: { height: 40 } }} sx={{ width: '475px' }} onChange={(e) => setSearchString(e.target.value)} />
                <Button sx={{ marginRight: 'auto' }} onClick={handleSearch}>
                    {translation.search}
                </Button>
                <div style={{ height: '64px', borderLeft: '1px solid #D9D9D9', margin: '0px 40px' }}></div>

                {/* <Button variant="contained" disabled sx={{width: '140px', margin: '5px'}} onClick={() => setOpenConfig(true)}><EditIcon sx={{marginRight: '10px'}}/>{translation.edit}</Button> */}
                <Button variant='contained' sx={{ width: '140px', minWidth: '100px', margin: '5px' }} onClick={createExcel}>
                    <CsvIcon className='svgIcon' style={{ marginRight: '10px' }} />
                    {translation.export}
                </Button>
                <Button variant='contained' sx={{ width: '140px', minWidth: '100px', margin: '5px' }} onClick={createPDF}>
                    <PdfIcon className='svgIcon' style={{ marginRight: '10px' }} />
                    {translation.export}
                </Button>
                {/* Edit Popup */}
                <Edit openConfig={openConfig} setOpenConfig={setOpenConfig} setSaveAlert={setSaveAlert} selectedDeviceId={selectedDeviceId} selectedIndex={selectedIndex} translation={translation.Edit} />
            </div>
            <TableContainer>
                <Table aria-label='collapsible table'>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    size='small'
                                    checked={records.length > 0 && selected.length === records.slice((page - 1) * 10, page * 10).length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                />
                            </TableCell>
                            <TableCell>{translation.Id}</TableCell>
                            <TableCell align='left'>{translation.status}</TableCell>
                            <TableCell align='left'>{translation.deviceId}</TableCell>
                            <TableCell align='left'>{translation.recordTime}</TableCell>
                            <TableCell align='left'>{translation.count}</TableCell>
                            <TableCell align='left'>{translation.duration}</TableCell>
                            <TableCell align='left'>{translation.note}</TableCell>
                            {/* <TableCell align="left">{translation.timeZone}</TableCell> */}
                            {/* <TableCell align="left">{translation.deliveryCompany}</TableCell> */}
                            {/* <TableCell align="left">{translation.trackNumber}</TableCell> */}
                            {/*<TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.slice((page - 1) * 10, page * 10).map((row) => {
                            const isItemSelected = isSelected(row.id);
                            return <Row key={row.deviceId+row.index} row={row} isItemSelected={isItemSelected} onClick={handleClick} translation={translation} />;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={10} alignItems={'center'} marginTop={2}>
                <Pagination count={pageCount} showFirstButton showLastButton variant='outlined' shape='rounded' page={page} onChange={handleChangePage} />
            </Stack>
            <div id='report' ref={printRef} style={{ display: 'block', width: '595pt', position: 'absolute', left: '-1000px' }}>
                <Report reportData={reportData} />
            </div>
        </div>
    );
}

export default Records;
