import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import './Information.css'
import { ReactComponent as Help } from '../images/help.svg';
import { getInformation, updateInformation } from '../api';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 11,
    },
}));


function Information(props) {

    const { translation, info, handleCompanyNameChange, handlePreferenceChange } = props;

    const [lock, setLock] = React.useState(true);
    const [companyName, setCompanyName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [displayLanguage, setDisplayLanguage] = React.useState('');
    const [displayTempUnit, setDisplayTempUnit] = React.useState('');
    const [displayTimeZone, setDisplayTimeZone] = React.useState('');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneOffset = new Date().getTimezoneOffset();
    const offsetHour = timeZoneOffset / 60;
    const offsetSign = offsetHour > 0 ? '-' : '+';
    const offsetString = `${timeZone} (GMT${offsetSign}${Math.abs(offsetHour).toString()})`

    useEffect(() => {
    //     // fetch data from API
    //     getInformation().then((data) => {
    //         console.log('information', data);
    //         setCompanyName(data.companyInfo.name);
    //         setPhoneNumber(data.companyInfo.phone_number);
    //         setEmail(data.companyInfo.email);

    //     });
        setCompanyName(info.name ? info.name : '');
        setPhoneNumber(info.mobilephone ? info.phone_number : '');
        setEmail(info.email ? info.email : '');
        setDisplayLanguage(info.preference ? info.preference.language : '');
        setDisplayTempUnit(info.preference ? info.preference.temp_unit : '');
        setDisplayTimeZone('');
    }, [props]);


    function saveHandler() {
        setLock(true);
    };

    function onSubmit(e) {

        e.preventDefault();

        // const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");

        // if (!emailRegex.test(e.target.email.value)) {
        //     alert('請輸入正確的電子郵件格式');
        //     return;
        // }

        // if (lock) {
        //     setLock(false);
        //     return;
        // }
        // setLock(true);

        const newInfo = {
            company_name: e.target.companyName.value,
            phone_number: e.target.phoneNumber.value,
            email: e.target.email.value,
            temp_unit: e.target.tempUnit.value,
            language: e.target.language.value,
        }
        handleCompanyNameChange(newInfo.company_name);
        handlePreferenceChange(newInfo.language, newInfo.temp_unit);
        setCompanyName(newInfo.company_name);
        setPhoneNumber(newInfo.phone_number);
        setEmail(newInfo.email);

        updateInformation(newInfo).then((data) => {
            console.log(data);
        });

    }

    return (
        <div style={{ padding: '30px', paddingTop: '10px', minWidth: '800px'}}>
            {/* <p style={{ margin: '0px 10px 10px 10px'}}>{translation.notice1}<LightTooltip title={translation.hint} placement='top'><Help className='help-icon'/></LightTooltip>{translation.notice2}</p> */}
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <p style={{color: '#000',fontFamily: 'Roboto', fontSize: '1.25rem', fontStyle: 'normal', fontWeight: '600'}}>{translation.account}</p>
                <hr/>
                <p style={{fontFamily: 'Roboto', fontSize: '0.875rem', fontStyle: 'normal', fontWeight: '500'}}>{translation.displayName}</p>
                <input className='info' type='text' id='companyName' placeholder='' defaultValue={companyName} disabled={true} fontSize='50px' style={{width: '392px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', verticalAlign: 'middle', backgroundColor: '#FFFFFF'}}/>
                <p style={{fontFamily: 'Roboto', fontSize: '0.875rem', fontStyle: 'normal', fontWeight: '500'}}>{translation.email}</p>
                <input className='info' type='text' id='email' placeholder='' defaultValue={email} disabled={true} style={{width: '392px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', verticalAlign: 'middle', backgroundColor: '#0000001A'}}/>
                <p style={{fontFamily: 'Roboto', fontSize: '0.875rem', fontStyle: 'normal', fontWeight: '500'}}>{translation.phone}</p>
                <input className='info' type='text' id='phoneNumber' placeholder='' defaultValue={phoneNumber} disabled={true} style={{width: '392px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', verticalAlign: 'middle', backgroundColor: '#0000001A'}}/>
                
                <p style={{marginTop: '30px', color: '#000',fontFamily: 'Roboto', fontSize: '1.25rem', fontStyle: 'normal', fontWeight: '600'}}>{translation.preference}</p>
                <hr/>
                <p style={{fontFamily: 'Roboto', fontSize: '0.875rem', fontStyle: 'normal', fontWeight: '500'}}>{translation.language}</p>
                <Select
                    native
                    value={displayLanguage}
                    disabled={false}
                    id="language"
                    onChange={(event) => {
                        setDisplayLanguage(event.target.value);
                    }}
                    // inputProps={{
                    // name: 'language',
                    // id: 'uncontrolled-native',
                    // }}
                    style={{fontSize: '0.875rem', margin: '0px', color: '#000', backgroundColor: '#FFF', display: 'inline-block', fontFamily: 'Roboto', fontWeight: '500'}}
                    size='small'
                    sx={{width: '15rem'}}
                >
                    <option value="english">English</option>
                    <option value="chinese">中文</option>
                </Select>
                <p style={{fontFamily: 'Roboto', fontSize: '0.875rem', fontStyle: 'normal', fontWeight: '500'}}>{translation.tempUnit}</p>
                <Select
                    native
                    value={displayTempUnit}
                    disabled={true}
                    id="tempUnit"
                    onChange={(event) => {
                    setDisplayTempUnit(event.target.value);
                    }}
                    // inputProps={{
                    // name: 'language',
                    // id: 'uncontrolled-native',
                    // }}
                    style={{fontSize: '0.875rem', margin: '0px', color: '#000', backgroundColor: '#FFF', display: 'inline-block', fontFamily: 'Roboto', fontWeight: '500'}}
                    size='small'
                    sx={{width: '15rem'}}
                >
                    <option value="celsius">{translation.celsius}</option>
                    <option value="fahrenheit">{translation.fahrenheit}</option>
                </Select>
                <p style={{fontFamily: 'Roboto', fontSize: '0.875rem', fontStyle: 'normal', fontWeight: '500'}}>{translation.timeZone}</p>
                <Select
                    native
                    value={'en'}
                    disabled={true}
                    // inputProps={{
                    // name: 'language',
                    // id: 'uncontrolled-native',
                    // }}
                    style={{fontSize: '0.875rem', margin: '0px', color: '#000', backgroundColor: '#EAE9E980', display: 'inline-block', fontFamily: 'Roboto', fontWeight: '500'}}
                    size='small'
                    sx={{width: '15rem'}}
                >
                    <option value="en">{offsetString}</option>
                    {/* <option value="en">Taipei (GMT+8)</option> */}
                    {/* <option value="ch">New York (GMT-4)</option> */}
                </Select>
                <br/>
                {/* <Button variant="contained" sx={{width: '180px', marginTop: '50px'}} onClick={onSubmit}>Save Changes</Button> */}
                <input className='info' type="submit" value={translation.save} disabled={false} style={{width: '180px', height: '36px', marginTop: '50px', borderRadius: '4px', border: 'none'}}/>
                {/* <Button variant="contained" sx={{width: '120px', margin: '10px'}} onClick={editHandler} disabled={!lock}>修改</Button> */}
                {/* <Button variant="contained" sx={{width: '120px', margin: '10px'}} onClick={saveHandler} disabled={lock}>儲存</Button> */}
                {/* <input className='info' type="button" value={translation.modify} style={{width: '120px', height: '36px', margin: '10px', borderRadius: '4px', border: 'none'}} disabled={!lock} onClick={editHandler}/>
                <input className='info' type="submit" value={translation.save} style={{width: '120px', height: '36px', margin: '10px', borderRadius: '4px', border: 'none'}} disabled={lock}/> */}
                {/* <div style={{ margin: '10px' }}>{translation.companyName}<input className='info' type='text' id='companyName' placeholder={translation.companyHint} defaultValue={companyName} fontSize='50px' disabled={lock} style={{width: '716px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> <div style={{ display: 'inline-block', verticalAlign: 'middle'}}> */}
                {/* <div style={{ margin: '10px' }}>{translation.phone}<input className='info' type='text' id='telephoneArea' placeholder={translation.districtCode} defaultValue={telephone[0]} disabled={lock} style={{width: '108px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/> <hr style={{width: '21px', height: '1px', background: 'black', border: 'none', display: 'inline-block', verticalAlign: 'middle'}}/> <input className='info' type='text' id='telephone' placeholder={translation.phone} defaultValue={telephone[1]} disabled={lock} style={{width: '176px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '0px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/> <hr style={{width: '21px', height: '1px', background: 'black', border: 'none', display: 'inline-block', verticalAlign: 'middle'}}/> <input className='info' type='text' id='telephoneExt' placeholder={translation.extension} defaultValue={telephone[2]} disabled={lock} style={{width: '108px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '0px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
                {/* <div style={{ margin: '10px' }}>{translation.companyAddress}<input className='info' type='text' id='addressPost' placeholder={translation.zipCode} defaultValue={address[0]} disabled={lock} style={{width: '108px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/> <hr style={{width: '21px', height: '1px', background: 'black', border: 'none', display: 'inline-block', verticalAlign: 'middle'}}/> <input className='info' type='text' id='address' placeholder={translation.address} defaultValue={address[1]} disabled={lock} style={{width: '580px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '0px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
                {/* <div style={{ margin: '10px' }}>{translation.mobilePhone}<input className='info' type='text' id='mobilephonePre' placeholder='09XX' defaultValue={mobilephone[0]} disabled={lock} style={{width: '108px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/> <hr style={{width: '21px', height: '1px', background: 'black', border: 'none', display: 'inline-block', verticalAlign: 'middle'}}/> <input className='info' type='text' id='mobilephone' placeholder='XXXXXX' defaultValue={mobilephone[1]} disabled={lock} style={{width: '176px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '0px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
                {/* <div style={{ margin: '10px' }}>{translation.email}<input className='info' type='text' id='email' placeholder='' defaultValue={email} disabled={lock} style={{width: '392px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
                {/* <div style={{ margin: '10px' }}>{translation.website}<input className='info' type='text' id='website' placeholder='' defaultValue={website} disabled={lock} style={{width: '392px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
                {/* <div style={{ margin: '10px' }}>{translation.line}<input className='info' type='text' id='lineaccount' placeholder='' defaultValue={lineaccount} disabled={lock} style={{width: '392px', height: '40px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '13px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
                {/* <div style={{ margin: '10px' }}>{translation.note}<textarea className='info' id='note' placeholder='' defaultValue={note} disabled={lock} style={{width: '716px', height: '180px', boxSizing: 'border-box', border: '1px solid rgba(117, 117, 117, 0.5)', borderRadius: '4px', marginLeft: '30px', verticalAlign: 'middle', backgroundColor: lock ? '#0000001A' : '#FFFFFF'}}/></div> */}
            </form>
        </div>

    );
}

export default Information;