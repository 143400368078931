import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { signup } from '../api';
import earth from '../images/earth.png';
import logo from '../images/logo-login.png';
import { country_code } from '../meta/country_code';
import './Signup.css';

function Signup(props) {
    const { setCompanyName, setLogin, handleLanguageChange, language, translation } = props;
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState('entry');
    const [emailDisplay, setEmailDisplay] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        const display_name = e.target.email.value;
        const country_code = '';
        const phone_number = '';
        // const display_name = e.target.displayName.value;
        // const country_code = e.target.countryCode.value;
        // const phone_number = e.target.phoneNumber.value;
        const agree = e.target.agree.checked;

        const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
        const passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'gm');

        if (!emailRegex.test(e.target.email.value)) {
            alert('Please enter a valid email address');
            return;
        }

        if (!passwordRegex.test(e.target.password.value)) {
            alert(translation.passwordCheck);
            return;
        }

        if (!agree) {
            alert('Please agree to the Term of Use and Privacy Policy');
            return;
        }

        setLoading(true);
        setEmailDisplay(email);

        signup(email, password, display_name, country_code, phone_number, language)
            .then((data) => {
                // console.log('signup: ', data);
                if (data && data.success) {
                    localStorage.setItem('token', data.token);
                    // localStorage.setItem('companyName', data.companyName);
                    setCompanyName(data.companyName);
                    setLogin(true);
                    setLoading(false);
                    setStage('success');
                } else {
                    setLoading(false);
                    alert('Signup failed');
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                alert('Connection failed');
            });
    };

    function redirectToLogin() {
        Navigate('/login');
    }

    return (
        <div style={{ height: '100vh', minWidth: '1000px', minHeight: '40rem', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-around', verticalAlign: 'center' }}>
            {/*<h1>Login</h1>*/}
            {/* <img src={logo} style={{width: '510px', height: '510px',paddingTop: '150px', backgroundColor: '#F323423'}}/> */}
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <div style={{ width: '28.5rem', height: '35rem', display: stage === 'entry' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <div style={{ width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center', position: 'absolute', top: '2rem' }}>
                            <img src={earth} style={{ width: '1rem', marginLeft: 'auto' }} />
                            <FormControl sx={{ m: 0, minWidth: 60 }} size='small'>
                                <Select
                                    native
                                    value={language}
                                    onChange={(event) => {
                                        handleLanguageChange(event.target.value);
                                    }}
                                    inputProps={{
                                        name: 'language',
                                        id: 'uncontrolled-native',
                                    }}
                                    style={{ fontSize: '10px', margin: '0px', display: 'inline-block', float: 'right', fontFamily: 'Roboto', fontWeight: '600' }}
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0},
                                        '& .MuiNativeSelect-select': {
                                            paddingRight: 4,
                                            paddingLeft: 1,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                    }}}
                                >
                                    <option value='english'>English</option>
                                    <option value='chinese'>中文</option>
                                </Select>
                            </FormControl>
                        </div>
                        <img src={logo} className='logo' style={{ width: '12.5rem', position: 'absolute', top: '2rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginTop: '2rem' }}>{translation.signup}</p>
                        <form onSubmit={onSubmit} style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                            <label className='signup' htmlFor='email' style={{ width: '21rem' }}>
                                {translation.email}
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input className='signup' id='email' type='text' placeholder='' name='email' />
                            <label className='signup' htmlFor='password' style={{ width: '21rem' }}>
                                {translation.password}
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input className='signup' id='password' type='password' placeholder='' style={{ marginBottom: '0' }} />
                            <p className='signup' style={{ width: '20rem', marginTop: '0', color: '#ADABAB', fontSize: '0.625rem' }}>
                                {translation.passwordCheck}
                            </p>
                            {/*<label className='signup' htmlFor='displayName' style={{ width: '21rem' }}>
                                {translation.displayName}
                            </label>
                            <input className='signup' id='displayName' type='text' placeholder='' name='displayName' />
                            <label className='signup' htmlFor='phoneNumber' style={{ width: '21rem' }}>
                                {translation.phoneNumber}
                            </label>
                            <div style={{ verticalAlign: 'middle' }}>
                                <select className='signup' id='countryCode' name='countryCode' open={true}>
                                    <option disabled selected value></option>
                                    {country_code
                                        .sort((a, b) => a.code.localeCompare(b.code))
                                        .map((item) => {
                                            return (
                                                <option value={item.code} style={{ fontFamily: 'Consolas, monospace' }}>
                                                    +{item.code.padEnd(6, '　')} {item.country}
                                                </option>
                                            );
                                        })}
                                </select>
                                <input className='signup' id='phoneNumber' type='tel' placeholder='' name='phoneNumber' />
                            </div>*/}

                            <div style={{ width: '21rem', marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
                                <input type='checkbox' id='agree' />
                                <label style={{ marginLeft: '5px' }}>
                                    {translation.agree} <a href='https://www.gocochain.com/privacy-policy-and-terms'>{translation.termOfUse}</a> {translation.and} <a href='https://www.gocochain.com/privacy-policy-and-terms'>{translation.privacyPolicy}</a>
                                </label>
                            </div>
                            {/* <input className='signup' type='submit' value='Create account' style={{ width: '9.25rem', height: '3rem', margin: '5px', borderRadius: '1.5rem' }} /> */}
                            <button className='signup blue' type='submit' style={{ width: '10rem', margin: '0.6rem' }}>
                                {translation.createAccount}
                            </button>
                        </form>
                        <div>
                            <p>
                                {translation.alreadyHave} <Link to='/login'>{translation.login}</Link>
                            </p>
                        </div>
                    </div>

                    <div style={{ width: '28.5rem', height: '30rem', display: stage === 'success' ? 'flex' : 'none', flexFlow: 'column nowrap', backgroundColor: '#FFFFFF', alignItems: 'center', justifyContent: 'center', borderRadius: '16px', position: 'relative' }}>
                        <img src={logo} className='logo' style={{ width: '12.5rem', marginBottom: '4rem' }} onClick={redirectToLogin} />
                        <p style={{ color: '#4A4A4A', fontSize: '1.25rem', fontWeight: '700', marginBottom: '2rem' }}>Please check your inbox</p>
                        <p className='complete'>A verification email has been sent to</p>
                        <p className='complete email'>{emailDisplay}.</p>
                        <p className='complete' style={{ marginBottom: '5rem' }}>
                            Please check and verify your account before logging in.
                        </p>
                        <div style={{ position: 'absolute', bottom: '0' }}>
                            <p>
                                Questions? Contact <a href='mailto:support@gocochain.com'>support@gocochain.com</a>
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Signup;
