import Panel from './components/Panel';
import DataPlot from './components/DataPlot';
import Rangebar from './components/Rangebar';
import { useState, useEffect } from 'react';
import { getOverview } from '../api';
import dayjs from 'dayjs';

function Overview(props){

    const [allCountList, setAllCount] = useState({});
    const [errCountList, setErrCount] = useState({});
    const [records, setRecords] = useState([]);
    const [uploadToday, setUploadToday] = useState(0);
    const [errorToday, setErrorToday] = useState(0);
    const [uploadWeek, setUploadWeek] = useState(0);
    const [errorWeek, setErrorWeek] = useState(0);
    const [errorRate, setErrorRate] = useState(0);
    const [errorSum, setErrorSum] = useState(0);
    const [normal, setNormal] = useState({});
    const [abnormal, setAbnormal] = useState({});
    const [rangetype, setRangetype] = useState('r1');
    const [failAverageTime, setFailAverageTime] = useState(0);
    const [errRate, setErrRate] = useState(0);

    // default time range
    let now = dayjs();
    let timeOffset = now.utcOffset();
    now = now.subtract(timeOffset, 'minute');
    let day = now.day();
    let start_time = now.subtract((day+6)%7, 'day').startOf('day');
    let end_time = now.endOf('day');
    let keys = [];
    let start = start_time;
    while (start <= end_time) {
        keys.push(start.format('YYYY-MM-DD'));
        start = start.add(1, 'day');
    }
    const [timeKeys, setTimeKeys] = useState(keys);

    const { translation } = props;

    useEffect(() => {
        getOverview(`${timeKeys[0]} 00:00:00`, `${timeKeys[timeKeys.length-1]} 23:59:59`).then((data) => {
        // getOverview(rangetype, `2023-01-01 00:00:00`, `2023-08-31 00:00:00`).then((data) => {
            setAllCount(data.upload_every_day);
            setErrCount(data.error_every_day);
            setRecords(data.records.Items);
            setUploadToday(data.upload_day);
            setErrorToday(data.error_day);
            setUploadWeek(data.upload_week);
            setErrorWeek(data.error_week);
            // setErrorSum(data.errCountList.reduce((a, b) => a + parseInt(b.count), 0));
            setNormal(data.normal);
            setAbnormal(data.abnormal);
            setFailAverageTime(data.fail_average_time);
            setErrRate(data.fail_rate);
        });
    }, [rangetype]);

    return (
        <>
            <Panel translation={translation} uploadToday={uploadToday} errorToday={errorToday} uploadWeek={uploadWeek} errorWeek={errorWeek}/>
            <Rangebar translation={translation} rangetype={rangetype} setRangetype={setRangetype} timeKeys={timeKeys} setTimeKeys={setTimeKeys}/>
            <DataPlot translation={translation} records allCountList={allCountList} errCountList={errCountList} overviewRecords={records} normal={normal} abnormal={abnormal} failAverageTime={failAverageTime} errRate={errRate} timeKeys={timeKeys}/>
        </>
    );
}

export default Overview;