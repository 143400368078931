function convertToSecond(value, unit) {
    switch (unit) {
        case 'second':
            return value;
        case 'minute':
            return value * 60;
        case 'hour':
            return value * 60 * 60;
        case 'day':
            return value * 60 * 60 * 24;
        default:
            return value;
    }
}

function secondToTime(value) {
    const unit = 'second';
    if (value % (60 * 60 * 24) === 0) {
        return { value: value / 60 / 60 / 24, unit: 'day' };
    }
    if (value % (60 * 60) === 0) {
        return { value: value / 60 / 60, unit: 'hour' };
    }
    if (value % 60 === 0) {
        return { value: value / 60, unit: 'minute' };
    }
    return { value, unit };
}

function convertToCelsius(value, unit) {
    switch (unit) {
        case 'celsius':
            return value;
        case 'fahrenheit':
            return (value - 32) * 5 / 9;
        default:
            return value;
    }
}

export { convertToSecond, secondToTime, convertToCelsius };