import React from "react";
import "./Rangebar.css";
import dayjs from "dayjs";

function Rangebar(props) {
    const { translation, rangetype, setRangetype, timeKeys, setTimeKeys } = props;

    // const [selected, setSelected] = React.useState('r1');

    const handleClick = (e) => {
        // setSelected(e.target.id);
        setRangetype(e.target.id);
        let now = dayjs();
        let timeOffset = now.utcOffset();
        now = now.subtract(timeOffset, 'minute');
        let day = now.day();
        let start_time = now.subtract((day+6)%7, 'day').startOf('day');
        let end_time = now.endOf('day');
        if (e.target.id === 'r2') {
            start_time = start_time.subtract(7, 'day');
            end_time = start_time.add(6, 'day').endOf('day');
        } else if (e.target.id === 'r3') {
            start_time = now.startOf('month');
            end_time = now.endOf('month');
        } else if (e.target.id === 'r4') {
            start_time = now.subtract(1, 'month').startOf('month');
            end_time = now.subtract(1, 'month').endOf('month');
        }
        
        let keys = [];
        let start = start_time;
        while (start <= end_time) {
            keys.push(start.format('YYYY-MM-DD'));
            start = start.add(1, 'day');
        }
        setTimeKeys(keys);

    };


    return (
        <>
            <div className="rangebar">
                <div className="rangebar__container">
                    <div className="rangebar__container__title">
                        <p>{translation.range}</p>
                    </div>

                    <div className="rangebar__container__item">
                        <button id='r1' className={rangetype === 'r1' ? 'active' : ''} onClick={handleClick}>{translation.currentWeek}</button>
                    </div>

                    <div className="vline"></div>

                    <div className="rangebar__container__item">
                        <button id='r2' className={rangetype === 'r2' ? 'active' : ''} onClick={handleClick}>{translation.lastWeek}</button>
                    </div>

                    <div className="vline"></div>

                    <div className="rangebar__container__item">
                        <button id='r3' className={rangetype === 'r3' ? 'active' : ''} onClick={handleClick}>{translation.currentMonth}</button>
                    </div>

                    <div className="vline"></div>

                    <div className="rangebar__container__item">
                        <button id='r4' className={rangetype === 'r4' ? 'active' : ''} onClick={handleClick}>{translation.lastMonth}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Rangebar;