import Background from '../../images/progress-background.png';
import Indicator from '../../images/indicator.png';
import './Progress.css'


function Progress(props) {

    const { errRate } = props;

    // const progress = 75;
    // const radius = 15;
    // const strokeWidth = 10;
    // const normalizedRadius = radius - strokeWidth * 2;
    // const circumference = normalizedRadius * 2 * Math.PI;
    // const strokeDashoffset = circumference - progress / 100 * circumference;

    // const arcLength = circumference * progress / 100;
    // const arcOffset = circumference - arcLength;

    // const [progress, setProgress] = useState(75);

    const size = 102
    const strokeWidth = 13
    const center = size / 2
    const radius = center - strokeWidth 
    const arcLength = 2 * Math.PI * radius 
    const arcOffset = arcLength * ((100 - errRate)/100);





    return(
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <img className='background' src={Background} alt='background'/>
            
            <svg className='progress' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{zIndex: 2}}>
                <circle cx="50" cy="50" r={radius} fill="transparent" strokeWidth={strokeWidth} stroke="#FF3838" strokeDasharray={arcLength} strokeDashoffset={arcOffset}/>
            </svg>
            <span className='percent'>{errRate}%</span>
            <img className='indicator' src={Indicator} alt='indicator'/>
            
        </div>
    )
}

export default Progress;

// margin: '20px 0 -400px 0',
// margin: '-20px 0 0 0'
// marginTop: '-274px', marginLeft: '-300px'
// margin: '-150px 0px 0 -250px', 